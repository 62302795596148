import clsx from 'clsx';
import { ComponentProps, FocusEvent } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';

interface InputProps extends ComponentProps<'input'> {
  label?: string;
  index?: number;
}

function RHFInput({
  type,
  name,
  placeholder,
  readOnly,
  maxLength,
  minLength,
  max,
  min,
  className,
  children,
  label,
  index,
  required,
  disabled,
  autoComplete = 'on',
  onBlur,
  value,
}: InputProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Form.Group controlId={index ? `${name}_${index}` : name} className={className}>
      <div>
        {label && (
          <Form.Label>
            {label}
            {required && <span className="required-asterisk"> *</span>}
          </Form.Label>
        )}
        <Controller
          name={name!}
          control={control}
          render={({ field }) => (
            <Form.Control
              type={type}
              className={clsx(errors[name!] && 'input-error')}
              {...field}
              placeholder={placeholder}
              maxLength={maxLength}
              minLength={minLength}
              value={value ? value : field.value}
              max={max}
              min={min}
              readOnly={readOnly}
              aria-describedby={index ? `${name}_${index}_error` : `${name}_error`}
              disabled={disabled}
              autoComplete={autoComplete}
              onBlur={(event: FocusEvent<HTMLInputElement, Element>) => {
                field.onBlur();
                onBlur && onBlur(event);
              }}
            />
          )}
        />
        {children}
      </div>
      {errors[name!] && <span className="text-error form-text">{errors[name!]?.message}</span>}
    </Form.Group>
  );
}

export default RHFInput;

import clsx from 'clsx';
import { ComponentProps } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';

interface SelectProps extends ComponentProps<'select'> {
  handleOnChange?: (targetValue: string) => void;
  label?: string;
  index?: number;
  isNumericValue?: boolean;
  noPlaceholder?: boolean;
}

const RHFSelect = ({
  handleOnChange,
  children,
  name,
  className,
  label,
  required,
  index,
  isNumericValue,
  disabled = false,
  noPlaceholder = false,
}: SelectProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Form.Group controlId={index ? `${name}_${index}` : name} className={clsx('input', className)}>
      {label && (
        <Form.Label>
          {label}
          {required && <span className="required-asterisk"> *</span>}
        </Form.Label>
      )}
      <Controller
        name={name!}
        control={control}
        render={({ field }) => (
          <Form.Control
            {...field}
            as="select"
            id={index ? `${name}_${index}` : name}
            value={field.value ?? ''}
            onChange={(e) => {
              const value = isNumericValue && e.target.value !== '' ? +e.target.value : e.target.value;

              field.onChange(value);

              handleOnChange && handleOnChange(String(value));
            }}
            disabled={disabled}
            className={clsx('input-div select', errors[name!] && 'input-error')}
          >
            {!noPlaceholder && <option value="">Seleccionar</option>}
            {children}
          </Form.Control>
        )}
      />
      {errors[name!] && <span className="text-error form-text">{errors[name!]?.message}</span>}
    </Form.Group>
  );
};

export default RHFSelect;

import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, OverlayTrigger, ProgressBar, Row, Tooltip } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import EditIcon from '../../../assets/Edit.svg';
import NewAgeDataIcon from '../../../assets/POS-activation.svg';
import QuestionIcon from '../../../assets/icon-question.svg';
import Select from '../../../components/Input/Select';
import NavigationFooter from '../../../components/NavigationFooter/NavigationFooter';
import { terminalPlan } from '../../../services/commerce.service';
import { TerminalPlanRequest } from '../../../types/business/request';
import { pagesPaths } from '../../../utils/navigationUtils';
import schemas from '../../../utils/schemas';

function CommerceCodes() {
  const navigate = useNavigate();
  const formRef = useRef<any>(null);
  const location: any = useLocation();
  const localState: any = location.state;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (localState?.nadOnboarding === false) {
      navigate(pagesPaths.CommerceDocumentation, {
        state: { ...localState, from: pagesPaths.CommerceConditions },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoBack = () => navigate(pagesPaths.CommerceTerminalsShipping, { state: { ...localState } });

  const handleSubmit = () => formRef.current.submitForm();

  const submitTerminalPlan = async (values: any) => {
    setIsLoading(true);

    const requestBody: TerminalPlanRequest = {
      id: localState?.terminalPlan?.id,
      commerceCodes: values.commerceCodes,
      allowInstallments: values.installments === 'yes',
      includesTips: values.tips === 'yes',
      ivaType: values.ivaType,
    };

    const response = await terminalPlan(requestBody, localState?.businessId || localState?.id);
    setIsLoading(false);

    if (response.data) {
      return navigate(pagesPaths.CommerceDocumentation, {
        state: { ...localState, ...response.data.businessData, from: pagesPaths.CommerceCodes },
      });
    }
  };

  const handleEdit = (id: string) => {
    const getInputById = document.getElementById(`${id}_input`);

    if (getInputById) {
      getInputById.focus();
      (getInputById as HTMLInputElement).value = '';
    }
    getInputById && getInputById.focus();
  };

  const questionIcon = (length?: number) => (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip>El código de comercio debe tener {!length ? '4 o 5' : length} dígitos.</Tooltip>}
    >
      <span className="h-100 justify-content-center align-items-center">
        <img alt="question" src={QuestionIcon} />
      </span>
    </OverlayTrigger>
  );

  const validate = (inputId: string, length?: number) => {
    const input: HTMLInputElement = document.getElementById(inputId) as HTMLInputElement;
    if (document.activeElement === input || input.value === '' || input.value.length === length) {
      input.setCustomValidity('');
    } else {
      if (input.value.length !== length) {
        if (inputId === 'tarjeta_d_input') {
          input.value.length > 5 || input.value.length < 4
            ? input.setCustomValidity('Debe tener 4 o 5 caracteres')
            : input.setCustomValidity('');
        } else {
          input.setCustomValidity(`Debe tener ${length} caracteres`);
        }
      } else {
        input.setCustomValidity('');
      }
    }
    input.reportValidity();
  };

  return (
    <>
      <Container className="commerce-terminals">
        <ProgressBar now={(8 / 12) * 100} />
        <Row>
          <Col lg={12}>
            <h1 className="title">
              <img src={NewAgeDataIcon} alt="NewAgeDataIcon" className="me-3" />
              Nueva Terminal New Age Data
            </h1>
            <Formik
              initialValues={{
                commerceCodes: {
                  amex: localState?.terminalPlan?.amexCode || '',
                  anda: localState?.terminalPlan?.andaCode || '',
                  cabal: localState?.terminalPlan?.cabalCode || '',
                  cabalDolar: localState?.terminalPlan?.cabalDolarCode || '',
                  clubDelEste: localState?.terminalPlan?.clubDelEsteCode || '',
                  creditel: localState?.terminalPlan?.creditelCode || '',
                  diners: localState?.terminalPlan?.dinersCode || '',
                  oca: localState?.terminalPlan?.ocaCode || '',
                  passcard: localState?.terminalPlan?.passcardCode || '',
                  tarjetaD: localState?.terminalPlan?.tarjetaDCode || '',
                  cdlcQr: localState?.terminalPlan?.cdlcQrCode || '',
                },
                installments: localState?.terminalPlan
                  ? localState?.terminalPlan.allowInstallments
                    ? 'yes'
                    : 'no'
                  : '',
                tips: localState?.terminalPlan ? (localState?.terminalPlan.includesTips ? 'yes' : 'no') : '',
                ivaType: localState?.terminalPlan ? localState?.terminalPlan.ivaType : '',
              }}
              onSubmit={submitTerminalPlan}
              validationSchema={schemas.CommerceCodesSchema}
              innerRef={(formElement: any) => (formRef.current = formElement)}
            >
              {({ setFieldValue, values, errors }) => (
                <Form>
                  <h3 className="section-title mt-4">CÓDIGO DE COMERCIO</h3>
                  <div className="table-wrapper">
                    <div className="table-title codes-table-cols mt-4">
                      {['Tarjeta', 'Adquiriente', 'Comercio'].map((heading: string, index: number) => (
                        <div key={`${heading}_${index}`}>{heading}</div>
                      ))}
                    </div>
                    <div className="table-description codes-table-cols">
                      <div>Master card</div>
                      <div>Adquirencia Getnet</div>
                      <div>Código de comercio Getnet</div>
                    </div>
                    <div className="table-description codes-table-cols">
                      <div>VISA</div>
                      <div>Adquirencia Getnet</div>
                      <div>Código de comercio Getnet</div>
                    </div>

                    <div className="table-description codes-table-cols">
                      <div>AMEX</div>
                      <div>-</div>
                      <div className="d-flex justify-content-evenly">
                        <input
                          className="codes-edit-input"
                          id="amex_input"
                          name="commerceCodes.amex"
                          onChange={(e: any) => setFieldValue('commerceCodes.amex', e.target.value)}
                          onBlur={(e: any) => validate(e.target.id, 10)}
                        />
                        <span
                          onClick={() => handleEdit('amex')}
                          className="h-100 justify-content-center align-items-center"
                        >
                          <img alt="edit" src={EditIcon} />
                        </span>
                        {questionIcon(10)}
                      </div>
                    </div>

                    <div className="table-description codes-table-cols">
                      <div>ANDA</div>
                      <div>-</div>
                      <div className="d-flex justify-content-evenly">
                        <input
                          className="codes-edit-input"
                          id="anda_input"
                          name="commerceCodes.anda"
                          onChange={(e: any) => setFieldValue('commerceCodes.anda', e.target.value)}
                          onBlur={(e: any) => validate(e.target.id, 12)}
                        />
                        <span
                          onClick={() => handleEdit('anda')}
                          className="h-100 justify-content-center align-items-center"
                        >
                          <img alt="edit" src={EditIcon} />
                        </span>
                        {questionIcon(12)}
                      </div>
                    </div>

                    <div className="table-description codes-table-cols">
                      <div>CABAL</div>
                      <div>-</div>
                      <div className="d-flex justify-content-evenly">
                        <input
                          className="codes-edit-input"
                          id="cabal_input"
                          name="commerceCodes.cabal"
                          onChange={(e: any) => setFieldValue('commerceCodes.cabal', e.target.value)}
                          onBlur={(e: any) => validate(e.target.id, 11)}
                        />
                        <span
                          onClick={() => handleEdit('cabal')}
                          className="h-100 justify-content-center align-items-center"
                        >
                          <img alt="edit" src={EditIcon} />
                        </span>
                        {questionIcon(11)}
                      </div>
                    </div>

                    <div className="table-description codes-table-cols">
                      <div>CABAL DÓLARES</div>
                      <div>-</div>
                      <div className="d-flex justify-content-evenly">
                        <input
                          className="codes-edit-input"
                          id="cabal_dolar_input"
                          name="commerceCodes.cabalDolar"
                          onChange={(e: any) => setFieldValue('commerceCodes.cabalDolar', e.target.value)}
                          onBlur={(e: any) => validate(e.target.id, 11)}
                        />
                        <span
                          onClick={() => handleEdit('cabal_dolar')}
                          className="h-100 justify-content-center align-items-center"
                        >
                          <img alt="edit" src={EditIcon} />
                        </span>
                        {questionIcon(11)}
                      </div>
                    </div>

                    <div className="table-description codes-table-cols">
                      <div>CLUB DEL ESTE</div>
                      <div>-</div>
                      <div className="d-flex justify-content-evenly">
                        <input
                          className="codes-edit-input"
                          id="club_del_este_input"
                          name="commerceCodes.clubDelEste"
                          onChange={(e: any) => setFieldValue('commerceCodes.clubDelEste', e.target.value)}
                          onBlur={(e: any) => validate(e.target.id, 4)}
                        />
                        <span
                          onClick={() => handleEdit('club_del_este')}
                          className="h-100 justify-content-center align-items-center"
                        >
                          <img alt="edit" src={EditIcon} />
                        </span>
                        {questionIcon(4)}
                      </div>
                    </div>

                    <div className="table-description codes-table-cols">
                      <div>CREDITEL</div>
                      <div>-</div>
                      <div className="d-flex justify-content-evenly">
                        <input
                          className="codes-edit-input"
                          id="creditel_input"
                          name="commerceCodes.creditel"
                          onChange={(e: any) => setFieldValue('commerceCodes.creditel', e.target.value)}
                          onBlur={(e: any) => validate(e.target.id, 6)}
                        />
                        <span
                          onClick={() => handleEdit('creditel')}
                          className="h-100 justify-content-center align-items-center"
                        >
                          <img alt="edit" src={EditIcon} />
                        </span>
                        {questionIcon(6)}
                      </div>
                    </div>

                    <div className="table-description codes-table-cols">
                      <div>LIDER/DINERS</div>
                      <div>-</div>
                      <div className="d-flex justify-content-evenly">
                        <input
                          className="codes-edit-input"
                          id="diners_input"
                          name="commerceCodes.diners"
                          onChange={(e: any) => setFieldValue('commerceCodes.diners', e.target.value)}
                          onBlur={(e: any) => validate(e.target.id, 8)}
                        />
                        <span
                          onClick={() => handleEdit('diners')}
                          className="h-100 justify-content-center align-items-center"
                        >
                          <img alt="edit" src={EditIcon} />
                        </span>
                        {questionIcon(8)}
                      </div>
                    </div>

                    <div className="table-description codes-table-cols">
                      <div>OCA PRODUCTOS</div>
                      <div>-</div>
                      <div className="d-flex justify-content-evenly">
                        <input
                          className="codes-edit-input"
                          id="oca_input"
                          name="commerceCodes.oca"
                          onChange={(e: any) => setFieldValue('commerceCodes.oca', e.target.value)}
                          onBlur={(e: any) => validate(e.target.id, 6)}
                        />
                        <span
                          onClick={() => handleEdit('oca')}
                          className="h-100 justify-content-center align-items-center"
                        >
                          <img alt="edit" src={EditIcon} />
                        </span>
                        {questionIcon(6)}
                      </div>
                    </div>

                    <div className="table-description codes-table-cols">
                      <div>PASSCARD</div>
                      <div>-</div>
                      <div className="d-flex justify-content-evenly">
                        <input
                          className="codes-edit-input"
                          id="passcard_input"
                          name="commerceCodes.passcard"
                          onChange={(e: any) => setFieldValue('commerceCodes.passcard', e.target.value)}
                          onBlur={(e: any) => validate(e.target.id, 5)}
                        />
                        <span
                          onClick={() => handleEdit('passcard')}
                          className="h-100 justify-content-center align-items-center"
                        >
                          <img alt="edit" src={EditIcon} />
                        </span>
                        {questionIcon(5)}
                      </div>
                    </div>

                    <div className="table-description codes-table-cols">
                      <div>TARJETA D</div>
                      <div>-</div>
                      <div className="d-flex justify-content-evenly">
                        <input
                          className="codes-edit-input"
                          id="tarjeta_d_input"
                          name="commerceCodes.tarjetaD"
                          onChange={(e: any) => setFieldValue('commerceCodes.tarjetaD', e.target.value)}
                          onBlur={(e: any) => validate(e.target.id)}
                        />
                        <span
                          onClick={() => handleEdit('tarjeta_d')}
                          className="h-100 justify-content-center align-items-center"
                        >
                          <img alt="edit" src={EditIcon} />
                        </span>
                        {questionIcon()}
                      </div>
                    </div>
                    <div className="table-description codes-table-cols">
                      <div>CRÉDITO DE LA CASA QR</div>
                      <div>-</div>
                      <div className="d-flex justify-content-evenly">
                        <input
                          className="codes-edit-input"
                          id="cdlc_qr_input"
                          name="commerceCodes.cdlcQr"
                          onChange={(e) => setFieldValue('commerceCodes.cdlcQr', e.target.value)}
                          onBlur={(e) => validate(e.target.id, 6)}
                        />
                        <span
                          onClick={() => handleEdit('cdlc_qr')}
                          className="h-100 justify-content-center align-items-center"
                        >
                          <img alt="edit" src={EditIcon} />
                        </span>
                        {questionIcon(6)}
                      </div>
                    </div>
                  </div>

                  <h3 className="section-title mt-6">TIPO DE PLAN</h3>
                  <Row>
                    <Select className="col-md-4" name="installments" label="Permite cuotas" required>
                      <option key="installments-yes" value="yes">
                        Si
                      </option>
                      <option key="installments-no" value="no">
                        No
                      </option>
                    </Select>
                    <Select className="col-md-4" name="tips" label="Incluye propina" required>
                      <option key="tip-yes" value="yes">
                        Si
                      </option>
                      <option key="tip-no" value="no">
                        No
                      </option>
                    </Select>
                    <Select className="col-md-4" name="ivaType" label="Tipo Tasa IVA" required>
                      <option key="iva-basic" value="0">
                        Básico
                      </option>
                      <option key="iva-extent" value="2">
                        Exento
                      </option>
                      <option key="iva-mixed" value="4">
                        Mixto
                      </option>
                      <option key="iva-min" value="1">
                        Mínima
                      </option>
                    </Select>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
      <NavigationFooter previousHandler={handleGoBack} nextHandler={handleSubmit} loading={isLoading} />
    </>
  );
}
export default CommerceCodes;

import DeleteBranchIcon from '../../../../../assets/delete-branch.svg';
import EditBranchIcon from '../../../../../assets/edit-branch.svg';
import { BranchOfficeList } from '../../../../../types/branchOffice/request';

interface BranchListItemProps {
  branch: BranchOfficeList;
  branchNumber: number;
  deleteBranchOffice: (branchOfficeId: number) => void;
  editBranch: (branchOfficeId: number, branchIndex: number) => void;
}

function BranchListItem({ branch, branchNumber, deleteBranchOffice, editBranch }: BranchListItemProps) {
  return (
    <div key={branchNumber} className="branch-list-item-container">
      <div className="branch-list-item-data-container">
        <p className="branch-list-item-name">Sucursal {branchNumber}</p>
        <p className="branch-list-item-city">{branch.fantasyName}</p>
      </div>
      <div className="branch-list-item-actions-container">
        <button
          className="branch-list-item-button"
          aria-label="Editar sucursal"
          onClick={() => editBranch(branch.id, branchNumber)}
        >
          <img src={EditBranchIcon} alt="" />
        </button>
        <button
          className="branch-list-item-button"
          aria-label="Eliminar sucursal"
          onClick={() => deleteBranchOffice(branch.id)}
        >
          <img src={DeleteBranchIcon} alt="" />
        </button>
      </div>
    </div>
  );
}

export default BranchListItem;

import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import RHFInput from '../../../../../components/ReactHookFormComponents/Input/RHFInput';
import RHFSelect from '../../../../../components/ReactHookFormComponents/Input/RHFSelect';
import { InfoBlockField } from '../../../../../types/add_commerce.types';
import CreateBranchSchemaType from '../../../../../types/branchOffice/branch-office-form';
import {
  AccountType,
  Bank,
  bankAccountTypes,
  banks,
  banksWithBranchAccount,
  banksWithSubaccount,
  currencies,
} from '../../../../../utils/data';
import { capitalize } from '../../../../../utils/helpers';

interface BranchBankAccountProps {
  bankAccountIndex: number;
  bankAccountsQuantity: number;
  insertBankAccount: any;
}

function BranchBankAccountForm({ bankAccountIndex, bankAccountsQuantity }: BranchBankAccountProps) {
  const { control, setValue } = useFormContext<CreateBranchSchemaType>();
  const bankAccountData = useWatch({ control, name: `bankAccounts.${bankAccountIndex}` });
  const bankAccountInitialValues = {
    id: null,
    bankExternalId: '',
    accountNumber: '',
    accountCurrency: '',
    accountBranch: '',
    accountHolder: '',
    accountType: '',
    subAccount: '',
    active: false,
  };

  const disableField = (field: string, chosenBank: string) => {
    const necessaryBanks = field === 'accountBranch' ? banksWithBranchAccount : banksWithSubaccount;

    return !necessaryBanks.includes(chosenBank);
  };

  useEffect(() => {
    setValue(`bankAccounts.${bankAccountIndex}`, bankAccountInitialValues);
  }, []);

  return (
    <>
      <fieldset></fieldset>
      <h3 className="branch-bank-account-form-title">Agregar una nueva cuenta bancaria</h3>
      <RHFSelect
        className="col-md-6"
        name={`bankAccounts.${bankAccountIndex}.bankExternalId`}
        label="Banco"
        required
      >
        {banks?.map((bank: Bank, index: number) => (
          <option key={bank.id} value={bank.id}>
            {bank.name}
          </option>
        ))}
      </RHFSelect>
      <RHFInput
        className="input col-md-6"
        type="text"
        name={`bankAccounts.${bankAccountIndex}.accountNumber`}
        label="N° de Cuenta"
        required
      >
        <small className="input-info">
          Especificaciones del número de cuenta según el banco seleccionado
        </small>
      </RHFInput>
      <RHFSelect
        className="col-md-6"
        name={`bankAccounts.${bankAccountIndex}.accountCurrency`}
        label="Moneda"
        required
        noPlaceholder
        disabled={bankAccountsQuantity === 2}
      >
        {currencies?.map((currency: InfoBlockField, index: number) => (
          <option key={currency.value} value={currency.value}>
            {capitalize(currency.name)}
          </option>
        ))}
      </RHFSelect>
      <RHFInput
        className="input col-md-6"
        type="text"
        name={`bankAccounts.${bankAccountIndex}.accountBranch`}
        label="N° de sucursal"
        disabled={disableField('accountBranch', bankAccountData?.bankExternalId)}
      />
      <RHFInput
        className="input col-md-6"
        type="text"
        name={`bankAccounts.${bankAccountIndex}.accountHolder`}
        label="Titular de la cuenta"
        required
      />
      <RHFSelect
        className="col-md-6"
        name={`bankAccounts.${bankAccountIndex}.accountType`}
        label="Tipo de cuenta"
        required
      >
        {bankAccountTypes?.map((accountType: AccountType, index: number) => (
          <option key={accountType.value} value={accountType.value}>
            {accountType.name}
          </option>
        ))}
      </RHFSelect>
      <RHFInput
        className="input col-md-6"
        type="text"
        name={`bankAccounts.${bankAccountIndex}.subAccount`}
        label="Subcuenta"
        disabled={disableField('subAccount', bankAccountData?.bankExternalId)}
      />
      <RHFSelect
        className="col-md-6"
        name={`bankAccounts.${bankAccountIndex}.active`}
        label="Cuenta activa"
        required
      >
        <option value="true">Sí</option>
        <option value="false">No</option>
      </RHFSelect>
    </>
  );
}

export default BranchBankAccountForm;

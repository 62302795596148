import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import RHFInput from '../../../../components/ReactHookFormComponents/Input/RHFInput';
import RHFSelect from '../../../../components/ReactHookFormComponents/Input/RHFSelect';
import { getBusinessCategories, getCities, getStates } from '../../../../services/commerce.service';
import {
  BusinessCategory,
  City,
  CommerceAddressState,
  Neighborhood,
  State,
} from '../../../../types/add_commerce.types';
import CreateBranchSchemaType from '../../../../types/branchOffice/branch-office-form';
import { sortArrayAlphabetically } from '../../../../utils/helpers';

interface BranchBasicInfoProps {
  branchData?: { city: City; neighborhood?: Neighborhood; state: State };
}

let sameAsBusinessInfo: boolean = false;

function BranchBasicInfo({ branchData }: BranchBasicInfoProps) {
  const location = useLocation();
  const localState = location.state as CommerceAddressState;

  const [businessCategories, setBusinessCategories] = useState<Array<BusinessCategory>>([]);
  const [states, setStates] = useState<Array<State>>([]);
  const [cities, setCities] = useState<Array<City>>([]);
  const [chosenState, setChosenState] = useState<State | undefined>(branchData?.city?.state);

  const { setValue } = useFormContext<CreateBranchSchemaType>();

  const fetchBusinessCategories = async () => {
    const responseBusinessCategories = await getBusinessCategories();
    if (Array.isArray(responseBusinessCategories.data)) {
      const sortedResponse = sortArrayAlphabetically(responseBusinessCategories.data, 'name');
      setBusinessCategories(sortedResponse);
    }
  };

  const fetchStates = async () => {
    const responseStates = await getStates();
    setStates(responseStates);
  };

  const fetchCities = async (state: State | undefined) => {
    if (state) {
      const responseCities = await getCities(state.id);
      return setCities(responseCities.data);
    }
  };

  const handleOnChangeState = (targetValue: string) => {
    const chosenState = states.find((state: State) => state?.id === +targetValue);

    setValue('state', String(chosenState?.id!));
    setChosenState(chosenState || undefined);
  };

  const handleOnChangeCity = (targetValue: string) => {
    setValue('city', targetValue);
  };

  useEffect(() => {
    fetchBusinessCategories();
  }, []);

  useEffect(() => {
    fetchStates();
    fetchCities(chosenState);
    branchData?.city?.state && fetchCities(branchData?.city?.state as State);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchData?.state, branchData?.city]);

  useEffect(() => {
    chosenState && fetchCities(chosenState);
  }, [chosenState]);

  const handleFillWithBranchData = async () => {
    sameAsBusinessInfo = !sameAsBusinessInfo;

    handleOnChangeState(sameAsBusinessInfo ? String(localState?.city?.stateId!) : '');
    handleOnChangeCity(sameAsBusinessInfo ? String(localState.city?.id!) : '');

    setValue('addressStreet', sameAsBusinessInfo ? localState.addressStreet : '');
    setValue('addressNumber', sameAsBusinessInfo ? localState.addressNumber! : '');
    setValue('addressFlatNumber', sameAsBusinessInfo ? localState.addressFlatNumber : '');
    setValue('addressSolar', sameAsBusinessInfo ? localState.addressSolar : '');
    setValue('addressPlot', sameAsBusinessInfo ? localState.addressPlot : '');
  };

  return (
    <Row>
      <RHFInput
        className={clsx('input', 'col-md-6')}
        type="text"
        name="fantasyName"
        label="Nombre Fantasía"
        required
      />
      <RHFSelect name="businessSubCategory" className="col-md-6" label="Sub rubro" required>
        {businessCategories?.map((category: BusinessCategory, index: number) => (
          <option value={category.id} key={`${category.id}_${index}`}>
            {category.name}
          </option>
        ))}
      </RHFSelect>
      <div className="input col-md-12 checkbox-label form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          name="sameAsBusinessInfo"
          onClick={() => handleFillWithBranchData()}
          id="flexSwitchCheckDefault"
        />
        <label htmlFor="flexSwitchCheckDefault">Dirección igual al Domicilio Principal</label>
      </div>
      <RHFSelect
        name="state"
        className="col-md-6"
        handleOnChange={handleOnChangeState}
        label="Departamento"
        required
      >
        {states.map((state: State, index: number) => (
          <option value={state?.id} key={`${state?.id}_${index}`}>
            {state?.name}
          </option>
        ))}
      </RHFSelect>
      <RHFSelect name="city" className="col-md-6" label="Localidad" required>
        {cities.map((city: City, index: number) => (
          <option value={city?.id} key={`${city?.id}_${index}`}>
            {city?.name}
          </option>
        ))}
      </RHFSelect>
      <RHFInput className="input col-12" type="text" name="addressStreet" label="Calle" required />
      <RHFInput className="input col-md-3" type="text" name="addressNumber" label="Nº de puerta" required />
      <RHFInput className="input col-md-3" type="number" name="addressFlatNumber" label="Apto (opcional)" />
      <RHFInput className="input col-md-3" type="number" name="addressSolar" label="Solar (opcional)" />
      <RHFInput className="input col-md-3" type="number" name="addressPlot" label="Manzana (opcional)" />
    </Row>
  );
}

export default BranchBasicInfo;

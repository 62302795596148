import { useFormikContext } from 'formik';
import { ChangeEvent } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import IssuerLogo from '../../../../assets/Issuer.svg';
import PersonalaizedTariffTable from '../../../../components/PersonalizedTariffTable/PersonalizedTariffTable';
import { CustomTableChunk } from '../../../../types/add_commerce.types';
import { masterPersonalizedComisionHeadings, visaPersonalizedComisionHeadings } from '../../../../utils/data';
import { triggerToasts } from '../../../../utils/helpers';

interface CommercePersonalizedTariffProps {
  acceptVisa: boolean;
  acceptMaster: boolean;
  masterData: any;
  visaData: any;
}

function CommercePersonalizedTariff({
  acceptVisa,
  acceptMaster,
  masterData,
  visaData,
}: CommercePersonalizedTariffProps) {
  const { setFieldValue } = useFormikContext();

  const handleBlurPersonalaizedTable = (
    event: ChangeEvent<HTMLInputElement>,
    tableData: Array<CustomTableChunk>,
    setFieldValue: (field: string, value: CustomTableChunk[]) => void,
    field: string,
  ) => {
    const value = event.target.value;
    const id = event.target.id.split('_')[1];
    const proposalValues = tableData[0].rows[parseInt(id)];
    const max = proposalValues.fees;
    const min = proposalValues.deferred;
    const newTableData = [...tableData];

    if (value > max) {
      newTableData[0].rows[parseInt(id)].currency = max;
      setFieldValue(field, newTableData);
      triggerToasts('El valor ingresado supera el máximo permitido', false);
    } else if (value < min) {
      newTableData[0].rows[parseInt(id)].currency = min;

      setFieldValue(field, newTableData);
      triggerToasts('El valor ingresado es menor al mínimo permitido', false);
    } else setFieldValue(field, newTableData);
  };

  const handleChangePersonalaizedTable = (
    event: ChangeEvent<HTMLInputElement>,
    tableData: Array<CustomTableChunk>,
    setFieldValue: (field: string, value: CustomTableChunk[]) => void,
    field: string,
  ) => {
    const value = event.target.value;
    const id = event.target.id.split('_')[1];
    const newTableData = [...tableData];

    newTableData[0].rows[parseInt(id)].currency = value;

    setFieldValue(field, newTableData);
  };

  return (
    <Container className="conditions-and-tariff">
      <Row>
        <Col lg={12}>
          <legend>
            <img src={IssuerLogo} alt="issuer-logo" className="me-3" />
            Tarifario Personalizado
          </legend>
          <br />
          {acceptMaster && (
            <>
              <p className="processor-title">MASTER</p>
              <PersonalaizedTariffTable
                tableData={masterData}
                headings={masterPersonalizedComisionHeadings}
                field="master"
                setFieldValue={setFieldValue}
                handleChangePersonalaizedTable={handleChangePersonalaizedTable}
                handleBlurPersonalaizedTable={handleBlurPersonalaizedTable}
              />
            </>
          )}
          {acceptVisa && (
            <>
              <p className="processor-title">VISA</p>
              <PersonalaizedTariffTable
                tableData={visaData}
                headings={visaPersonalizedComisionHeadings}
                field="visa"
                setFieldValue={setFieldValue}
                handleBlurPersonalaizedTable={handleBlurPersonalaizedTable}
                handleChangePersonalaizedTable={handleChangePersonalaizedTable}
              />
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default CommercePersonalizedTariff;

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import AcceptIcon from '../../../../assets/acept-icon.svg';
import CancelIcon from '../../../../assets/cancel-icon.svg';
import TrashIcon from '../../../../assets/trash-icon.svg';
import RHFInput from '../../../../components/ReactHookFormComponents/Input/RHFInput';
import RHFSelect from '../../../../components/ReactHookFormComponents/Input/RHFSelect';
import { validateBankAccounts } from '../../../../services/commerce.service';
import { BankAccountState, PrometeoData } from '../../../../types/add_commerce.types';
import { BankAccountValidationResponse } from '../../../../types/bankAccount/response';
import CreateBranchSchemaType from '../../../../types/branchOffice/branch-office-form';
import { UpdateBankAccountRequest } from '../../../../types/branchOffice/request';
import { BankAccount, BankAccountRequest } from '../../../../types/business/request';
import { currencyDictionary, CurrencyDictionary } from '../../../../utils/data';
import { capitalize, triggerToasts } from '../../../../utils/helpers';
import ConfirmBankModal from '../../BankAccount/ConfirmBankModal';
import BranchBankAccountForm from './BranchBankAccountForm/BranchBankAccountForm';

interface BranchBankAccountProps {
  bankAccountIndex: 0 | 1;
}

function BranchBankAccount({ bankAccountIndex }: BranchBankAccountProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const localState: BankAccountState = location.state as BankAccountState;
  const [bankAccountsQuantity, setBankAccountsQuantity] = useState<number>(0);
  const [showModal, setShowModal] = useState(false);
  const [prometeoData, setPrometeoData] = useState<PrometeoData[]>([]);
  const [prometeoError, setPrometeoError] = useState<boolean>(false);
  const [showBankAccountForm, setShowBankAccountForm] = useState<boolean>(false);
  const [showBankAccountSelector, setShowBankAccountSelector] = useState<boolean>(false);
  const [existingBankAccounts, setExistingBankAccounts] = useState<UpdateBankAccountRequest[]>(
    localState.bankAccounts || [],
  );
  const { control } = useFormContext<CreateBranchSchemaType>();

  const {
    fields: bankAccountFields,
    append: appendBankAccount,
    remove: removeBankAccount,
    insert: insertBankAccount,
  } = useFieldArray({
    control,
    name: 'bankAccounts',
    keyName: '_id',
  });

  const bankAccounts = useWatch({ control, name: 'bankAccounts' });

  const addExistingBankAccount = (bankAccountId: string) => {
    const selectedBankAccount = existingBankAccounts.find((bankAccount) => bankAccount.id === +bankAccountId);
    const newExistingBankAccounts = existingBankAccounts.filter(
      (bankAccount) => bankAccount.id !== selectedBankAccount?.id,
    );

    appendBankAccount(selectedBankAccount!);

    setExistingBankAccounts(newExistingBankAccounts);
    setBankAccountsQuantity(bankAccountsQuantity + 1);
    setShowBankAccountSelector(false);
  };

  const toggleModal = () => setShowModal((prevState: boolean) => !prevState);

  const handleModal = async () => {
    const bankAccountData = bankAccounts[bankAccountIndex] as BankAccount;
    const requestBody: BankAccountRequest = {
      bankAccounts: [bankAccountData],
    };

    const response = await validateBankAccounts(localState.id!, requestBody);
    const data: BankAccountValidationResponse = response.data;

    if (response.result && data) {
      if (data.isPrometeoOutOfService) {
        setPrometeoError(true);
      } else {
        setPrometeoError(false);
        setPrometeoData(response.data.prometeoData);
      }

      toggleModal();
    } else {
      triggerToasts(response);
    }
  };

  const handleBankAccountValidation = (values: BankAccountRequest) => {
    setShowBankAccountForm(false);

    toggleModal();
  };

  const bankAccountValues = { bankAccounts: [bankAccounts[bankAccountIndex]] } as BankAccountRequest;

  const deleteSelectedBankAccount = (index: number, bankAccount: any) => {
    removeBankAccount(index);

    if (bankAccount.id) {
      const newExistingBankAccounts = [...existingBankAccounts, bankAccount];

      setExistingBankAccounts(newExistingBankAccounts);
    }

    setBankAccountsQuantity(bankAccountsQuantity - 1);
  };

  const cancelBankAccountForm = () => {
    setShowBankAccountForm(false);
    removeBankAccount(bankAccountFields.length ? bankAccountFields.length - 1 : 0);
  };

  return (
    <Container className="branch-bank-account">
      <Row>
        <Col lg={12}>
          <fieldset>
            <legend className="branch-form-title">CUENTA BANCARIA</legend>
            <hr />
            <p>
              Puedes asignar a la sucursal una cuenta bancaria en pesos y otra en dólares de las que tenías
              validadas anteriormente o ingresar una nueva cuenta.
            </p>
            {!!bankAccounts.length &&
              bankAccounts.map((bankAccount, index: number) => {
                const bankCurrencyProperty = +bankAccount.accountCurrency as keyof CurrencyDictionary;

                return (
                  <Row key={index} className="branch-bank-account-selected-container">
                    <RHFInput
                      className="col-md-6"
                      name=""
                      type="text"
                      label="Cuenta bancaria asignada a la sucursal"
                      disabled
                      required
                      value={`${capitalize(bankAccount.accountHolder)} - ${bankAccount.accountNumber}`}
                    />
                    <RHFInput
                      className="col-md-5"
                      name=""
                      label="Moneda"
                      type="text"
                      value={currencyDictionary[bankCurrencyProperty]}
                      disabled
                    />
                    <Button
                      className="branch-bank-account-delete-button"
                      aria-label="Eliminar banco seleccionado"
                      onClick={() => deleteSelectedBankAccount(index, bankAccount)}
                    >
                      <img src={TrashIcon} alt="" />
                    </Button>
                  </Row>
                );
              })}
            <Row>
              {showBankAccountSelector && (
                <>
                  <RHFSelect
                    className="col-md-6"
                    name=""
                    label="Cuenta bancaria asignada a la sucursal"
                    handleOnChange={addExistingBankAccount}
                    required
                  >
                    {existingBankAccounts.map((bankAcount, index) => (
                      <option key={index} value={bankAcount.id!}>
                        {capitalize(bankAcount.accountHolder)} - {bankAcount.accountNumber}
                      </option>
                    ))}
                  </RHFSelect>
                  <RHFInput className="col-md-6" name="" label="Moneda" type="text" disabled />
                </>
              )}
            </Row>
            <Row className="branch-bank-account-form-container">
              {showBankAccountForm && (
                <BranchBankAccountForm
                  bankAccountIndex={bankAccountFields.length ? bankAccountFields.length - 1 : 0}
                  bankAccountsQuantity={bankAccountsQuantity}
                  insertBankAccount={insertBankAccount}
                />
              )}
              {bankAccountFields.length < 2 && (
                <div className="branch-bank-account-buttons-container">
                  {!showBankAccountForm ? (
                    <div className="branch-bank-account-add-buttons">
                      <Button
                        onClick={() => setShowBankAccountSelector(true)}
                        className="share-button add-button"
                      >
                        <FontAwesomeIcon icon={faPlus as IconProp} />
                        Agregar cuenta existente
                      </Button>
                      <Button
                        onClick={() => {
                          setShowBankAccountForm(true);
                          setShowBankAccountSelector(false);
                        }}
                        className="share-button add-button"
                      >
                        <FontAwesomeIcon icon={faPlus as IconProp} />
                        Ingresar nueva cuenta
                      </Button>
                    </div>
                  ) : (
                    <div className="branch-bank-account-add-buttons">
                      <Button onClick={cancelBankAccountForm} className="share-button add-button">
                        <img className="branch-bank-account-buttons-icons" src={CancelIcon} alt="" />
                        Cancelar
                      </Button>
                      <Button onClick={() => handleModal()} className="share-button add-button">
                        <img className="branch-bank-account-buttons-icons" src={AcceptIcon} alt="" />
                        Validar cuenta bancaria
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </Row>
          </fieldset>
        </Col>
      </Row>
      <ConfirmBankModal
        show={showModal}
        toggleModal={toggleModal}
        prometeoValues={prometeoData}
        prometeoError={prometeoError}
        handleNextStep={handleBankAccountValidation}
        values={bankAccountValues}
      />
    </Container>
  );
}

export default BranchBankAccount;

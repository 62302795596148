import { Row } from 'react-bootstrap';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import RHFRoundedCheckbox from '../../../../../components/ReactHookFormComponents/Input/RHFRoundedCheckbox';
import RHFSwitch from '../../../../../components/ReactHookFormComponents/Input/RHFSwitch';
import CreateBranchSchemaType from '../../../../../types/branchOffice/branch-office-form';
import { ECommerceProcessorEnum } from '../../../../../types/enums/ecommerce-processor.enum';
import { integrationType } from '../../../../../utils/data';
import { capitalize } from '../../../../../utils/helpers';

interface CheckboxOption {
  name: string;
  value: number;
}

function Ecommerce() {
  const { control, setValue, getValues } = useFormContext<CreateBranchSchemaType>();
  const hasEcommerceValue = useWatch({ control, name: 'hasEcommerce' });
  const paymentProcessorValue = useWatch({ control, name: 'ecommerce.processor' });
  const integrationTypes = useWatch({ control, name: 'ecommerce.integrationType' });

  const handleCheckboxIntegrationType = (option: { name: string; value: number }) => {
    const newValues = integrationTypes.includes(option.value)
      ? integrationTypes.filter((value) => value !== option.value)
      : [...integrationTypes, option.value];

    setValue('ecommerce.integrationType', newValues);
  };

  return (
    <Row className="text-center">
      <div className="branch-ecommerce-switchs-cotainer">
        <RHFSwitch
          id="automatic_debit_switch"
          label="¿El comercio trabaja con sistema PAR?"
          name="hasPar"
          containerClass="col-md-12 mt-0 px-0"
        />
        <RHFSwitch
          id="ecommerce_switch"
          label="Agregar e-commerce al comercio"
          name="hasEcommerce"
          containerClass="col-md-12 mt-0 px-0"
        />
      </div>
      {hasEcommerceValue && (
        <>
          <div className="col-md-4">
            <fieldset>
              <legend className="subtitle mb-2 ecommerce-legend">¿Tienes pasarela de pago?</legend>
              <div className="col-md-12 mt-1">
                <RHFRoundedCheckbox
                  name="ecommerce.processor"
                  label={capitalize(ECommerceProcessorEnum.PLEXO)}
                  value={ECommerceProcessorEnum.PLEXO}
                  customClass="px-0"
                  currentComboCheckboxValue={paymentProcessorValue!}
                />
                <RHFRoundedCheckbox
                  name="ecommerce.processor"
                  label={capitalize(ECommerceProcessorEnum.BAMBOO)}
                  value={ECommerceProcessorEnum.BAMBOO}
                  currentComboCheckboxValue={paymentProcessorValue!}
                />
                <RHFRoundedCheckbox
                  name="ecommerce.processor"
                  label={capitalize(ECommerceProcessorEnum.OTRO)}
                  value={ECommerceProcessorEnum.OTRO}
                  currentComboCheckboxValue={paymentProcessorValue!}
                />
              </div>
            </fieldset>
          </div>
          <div className="col-md-12 d-flex flex-column mt-4">
            <fieldset>
              <legend className="subtitle mb-2 ecommerce-legend">
                ¿Va a adquirir alguno de los siguientes productos?
              </legend>
              <div className="col-md-8 d-flex">
                {integrationType.map((option: CheckboxOption, index: number) => (
                  <label
                    key={`${option.name}_${index}`}
                    className="checkbox-label col-3 d-flex align-items-center mt-1"
                    htmlFor={option.name}
                  >
                    <Controller
                      name="ecommerce.integrationType"
                      control={control}
                      render={({ field }) => (
                        <input
                          className="checkbox"
                          type="checkbox"
                          id={option.name}
                          checked={field.value?.includes(option.value)}
                          onChange={() => handleCheckboxIntegrationType(option)}
                        />
                      )}
                    />
                    {option.name}
                  </label>
                ))}
              </div>
            </fieldset>
          </div>
        </>
      )}
    </Row>
  );
}

export default Ecommerce;

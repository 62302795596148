import { ComponentProps } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

interface SwitchProps extends ComponentProps<'switch'> {
  handleOnClick?: () => void;
  label: string;
  containerClass?: string;
}

function RHFSwitch({ handleOnClick, name, id, label, containerClass }: SwitchProps) {
  const { control } = useFormContext();

  return (
    <div className={`input checkbox-label form-check form-switch ${containerClass}`}>
      <Controller
        name={name!}
        control={control}
        render={({ field }) => (
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id={id}
            {...field}
            onChange={(e) => {
              field.onChange(e);
              handleOnClick?.();
            }}
            checked={field.value}
          />
        )}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}

export default RHFSwitch;

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import BankIcon from '../../../assets/icon-bank-active.svg';
import Input from '../../../components/Input/Input';
import Select from '../../../components/Input/Select';
import NavigationFooter from '../../../components/NavigationFooter/NavigationFooter';
import { updateBankAccount, validateBankAccounts } from '../../../services/commerce.service';
import { BankAccountState, InfoBlockField, PrometeoData } from '../../../types/add_commerce.types';
import { BankAccountValidationResponse } from '../../../types/bankAccount/response';
import { BankAccount, BankAccountRequest } from '../../../types/business/request';
import { CurrencyEnum } from '../../../types/enums/currency.enum';
import {
  AccountType,
  Bank,
  bankAccountTypes,
  banks,
  banksWithBranchAccount,
  banksWithSubaccount,
  currencies,
} from '../../../utils/data';
import { capitalize, triggerToasts } from '../../../utils/helpers';
import { pagesPaths } from '../../../utils/navigationUtils';
import schemas from '../../../utils/schemas';
import ConfirmBankModal from './ConfirmBankModal';

function CommerceBankAccount() {
  const location = useLocation();
  const navigate = useNavigate();
  const formRef = useRef<any>();
  const localState: BankAccountState = location.state as BankAccountState;
  const [bankAccountsQuantity, setBankAccountsQuantity] = useState<0 | 1 | 2>(1);
  const [showTrackNumberInput, setShowTrackNumberInput] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [prometeoData, setPrometeoData] = useState<PrometeoData[]>([]);
  const [prometeoError, setPrometeoError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (localState.bankAccounts && localState.bankAccounts.length === 2) {
      setBankAccountsQuantity(2);
    }

    if (localState.accountTrackingNumber) {
      setShowTrackNumberInput(true);
    }
  }, []);

  const initialBankAccountValues = {
    accountNumber: '',
    accountHolder: '',
    accountBranch: '',
    accountType: '',
    subAccount: '',
    isActive: '',
  };

  const handleGoBack = () => navigate(pagesPaths.CommerceAddress, { state: { ...localState } });

  const getBankAccountRequestBody = (bankAccountRequest: BankAccountRequest) => {
    let requestBody: BankAccountRequest;
    let bankAccountsIdAdded: BankAccount[];

    if (bankAccountRequest.bankAccounts?.length === 0) {
      requestBody = {
        bankAccountTrackingNumber: bankAccountRequest?.bankAccountTrackingNumber,
        bankAccounts: [],
      };
    } else if (bankAccountRequest.bankAccounts?.length === 2) {
      requestBody = {
        bankAccountTrackingNumber: bankAccountRequest?.bankAccountTrackingNumber,
        bankAccounts: [bankAccountRequest.bankAccounts[0], bankAccountRequest.bankAccounts[1]],
      };

      if (localState.bankAccounts?.length === 1) {
        bankAccountsIdAdded = [
          { ...requestBody.bankAccounts![0], id: localState.bankAccounts[0].id },
          { ...requestBody.bankAccounts![1] },
        ];

        requestBody = {
          bankAccountTrackingNumber: bankAccountRequest?.bankAccountTrackingNumber,
          bankAccounts: bankAccountsIdAdded,
        };
      }

      if (localState.bankAccounts?.length === 2) {
        bankAccountsIdAdded = [
          { ...requestBody.bankAccounts![0], id: localState.bankAccounts[0].id },
          { ...requestBody.bankAccounts![1], id: localState.bankAccounts[1].id },
        ];

        requestBody = {
          bankAccountTrackingNumber: bankAccountRequest?.bankAccountTrackingNumber,
          bankAccounts: bankAccountsIdAdded,
        };
      }
    } else {
      requestBody = {
        bankAccountTrackingNumber: bankAccountRequest?.bankAccountTrackingNumber,
        bankAccounts: bankAccountRequest?.bankAccounts,
      };

      if (localState.bankAccounts?.length === 1 || localState.bankAccounts?.length === 2) {
        bankAccountsIdAdded = [{ ...requestBody.bankAccounts![0], id: localState.bankAccounts[0].id }];

        requestBody = {
          bankAccountTrackingNumber: bankAccountRequest?.bankAccountTrackingNumber,
          bankAccounts: bankAccountsIdAdded,
        };
      }
    }

    return requestBody;
  };

  const handleNextStep = async (values: BankAccountRequest) => {
    setLoading(true);
    const requestBody: BankAccountRequest = getBankAccountRequestBody(values);

    if (requestBody.bankAccounts && !prometeoError) {
      for (let index = 0; index < requestBody.bankAccounts?.length!; index++) {
        requestBody.bankAccounts[index].prometeoAccountHolder = prometeoData[index].accountHolder;
      }
    }

    const response = await updateBankAccount(localState.id!, requestBody);

    if (response.result && response.data) {
      const businessData = response.data.businessData || response.data;

      setLoading(false);

      return navigate(pagesPaths.CommerceBranches, { state: { ...localState, ...businessData } });
    }
    triggerToasts(response);
    setLoading(false);
  };

  const handleShowTrackNumber = () => setShowTrackNumberInput((prevState: boolean) => !prevState);

  const handleAddAccount = (val: any, setFieldValue: (field: string, value: any) => void) => {
    setFieldValue('bankAccounts[1]', initialBankAccountValues);
    setFieldValue(
      'bankAccounts[1].accountCurrency',
      String(currencies.find((curr) => String(curr.value) !== val.accountCurrency)?.value),
    );
    setBankAccountsQuantity(2);
    setFieldValue('accountsQuantity', 2);
  };

  const handleDeleteAccount = (setFieldValue: (field: string, value: any) => void, accounts: any) => {
    setBankAccountsQuantity(1);
    setFieldValue('accountsQuantity', 1);
    setFieldValue('bankAccounts', [{ ...accounts[0] }]);
  };

  const handleOnClickSwitch = (setFieldValue: (field: string, value: any) => void, resetForm: () => void) => {
    resetForm();
    if (bankAccountsQuantity === 0) {
      setFieldValue('bankAccounts[0]', initialBankAccountValues);
      setFieldValue(
        'bankAccounts[0].accountCurrency',
        String(currencies.find((curr) => curr.value === CurrencyEnum.URUGUAYAN_PESOS)?.value),
      );
      setFieldValue('bankAccounts[0].bankExternalId', '');
      setBankAccountsQuantity(1);
      setFieldValue('accountsQuantity', 1);
    } else {
      setBankAccountsQuantity(0);
      setFieldValue('accountsQuantity', 0);
      setFieldValue('bankAccounts', []);
      setShowTrackNumberInput(true);
    }
  };

  const disableField = (field: string, chosenBank: string) => {
    const necessaryBanks = field === 'accountBranch' ? banksWithBranchAccount : banksWithSubaccount;
    return !necessaryBanks.includes(chosenBank);
  };

  const toggleModal = () => setShowModal((prevState: boolean) => !prevState);

  const handleModal = async (values: BankAccountRequest | undefined) => {
    if (bankAccountsQuantity > 0) {
      const requestBody: BankAccountRequest = getBankAccountRequestBody(values!);

      const response = await validateBankAccounts(localState.id!, requestBody);
      const data: BankAccountValidationResponse = response.data;

      if (response.result && data) {
        if (data.isPrometeoOutOfService) {
          setPrometeoError(true);
        } else {
          setPrometeoError(false);
          setPrometeoData(response.data.prometeoData);
        }

        toggleModal();
      } else {
        triggerToasts(response);
      }
    } else {
      values && handleNextStep(values);
    }
  };

  const handleSubmit = () => formRef?.current?.handleSubmit();

  return (
    <>
      <Container className="bank-account">
        <ProgressBar now={(100 / 8) * 3} />
        <Row>
          <Col lg={12}>
            <Formik
              innerRef={(formElement: any) => (formRef.current = formElement)}
              initialValues={{
                bankAccountTrackingNumber: localState?.accountTrackingNumber || '',
                accountsQuantity: bankAccountsQuantity,
                bankAccounts:
                  localState.bankAccounts && localState.bankAccounts.length === 2
                    ? [
                        {
                          bankExternalId: localState?.bankAccounts.length
                            ? localState.bankAccounts[0].bankExternalId
                            : '',
                          accountNumber: localState?.bankAccounts.length
                            ? localState.bankAccounts[0].accountNumber
                            : '',
                          accountHolder: localState?.bankAccounts.length
                            ? localState.bankAccounts[0].accountHolder
                            : '',
                          accountBranch: localState?.bankAccounts.length
                            ? localState.bankAccounts[0].accountBranch
                            : '',
                          accountType: localState?.bankAccounts.length
                            ? localState.bankAccounts[0].accountType
                            : '',
                          subAccount: localState?.bankAccounts.length
                            ? localState.bankAccounts[0].subAccount
                            : '',
                          isActive: localState?.bankAccounts.length ? localState.bankAccounts[0].active : '',
                          accountCurrency: localState?.bankAccounts.length
                            ? localState.bankAccounts[0].accountCurrency
                            : '1',
                        },
                        {
                          bankExternalId: localState?.bankAccounts.length
                            ? localState.bankAccounts[1].bankExternalId
                            : '',
                          accountNumber: localState?.bankAccounts.length
                            ? localState.bankAccounts[1].accountNumber
                            : '',
                          accountHolder: localState?.bankAccounts.length
                            ? localState.bankAccounts[1].accountHolder
                            : '',
                          accountBranch: localState?.bankAccounts.length
                            ? localState.bankAccounts[1].accountBranch
                            : '',
                          accountType: localState?.bankAccounts.length
                            ? localState.bankAccounts[1].accountType
                            : '',
                          subAccount: localState?.bankAccounts.length
                            ? localState.bankAccounts[1].subAccount
                            : '',
                          isActive: localState?.bankAccounts.length ? localState.bankAccounts[1].active : '',
                          accountCurrency: localState?.bankAccounts.length
                            ? localState.bankAccounts[1].accountCurrency
                            : '2',
                        },
                      ]
                    : [
                        {
                          bankExternalId: localState?.bankAccounts?.length
                            ? localState.bankAccounts[0].bankExternalId
                            : '',
                          accountNumber: localState?.bankAccounts?.length
                            ? localState.bankAccounts[0].accountNumber
                            : '',
                          accountHolder: localState?.bankAccounts?.length
                            ? localState.bankAccounts[0].accountHolder
                            : '',
                          accountBranch: localState?.bankAccounts?.length
                            ? localState.bankAccounts[0].accountBranch
                            : '',
                          accountType: localState?.bankAccounts?.length
                            ? localState.bankAccounts[0].accountType
                            : '',
                          subAccount: localState?.bankAccounts?.length
                            ? localState.bankAccounts[0].subAccount
                            : '',
                          isActive: localState?.bankAccounts?.length ? localState.bankAccounts[0].active : '',
                          accountCurrency: localState?.bankAccounts?.length
                            ? localState.bankAccounts[0].accountCurrency
                            : '1',
                        },
                      ],
              }}
              validationSchema={schemas.CommerceBankAccountSchema}
              onSubmit={handleModal}
            >
              {({ values, setFieldValue, resetForm }) => (
                <Form>
                  <fieldset>
                    <legend>
                      <img className="bank-icon" src={BankIcon} alt="" />
                      Cuenta bancaria
                    </legend>
                    <p>
                      Agrega las cuentas bancarias de tu comercio. En el próximo paso podrás asignarlas a las
                      sucursales que configures o ingresar nuevas cuentas.
                    </p>
                    <Row>
                      <div className="input col-md-12 checkbox-label form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          name="addECommerce"
                          id="SwitchAddECommerce"
                          onClick={() => handleOnClickSwitch(setFieldValue, resetForm)}
                        />
                        <label htmlFor="SwitchAddECommerce">No posee cuenta bancaria</label>
                      </div>
                      {bankAccountsQuantity > 0 && (
                        <>
                          <Select
                            className="col-md-6"
                            name="bankAccounts[0].bankExternalId"
                            label="Banco"
                            required
                          >
                            {banks?.map((bank: Bank) => (
                              <option key={bank.id} value={bank.id}>
                                {bank.name}
                              </option>
                            ))}
                          </Select>
                          <Input
                            className="input col-md-6"
                            type="text"
                            name="bankAccounts[0].accountNumber"
                            label="N° de Cuenta"
                            required
                          >
                            <small className="input-info">
                              Especificaciones del número de cuenta según el banco seleccionado
                            </small>
                          </Input>
                          <Select
                            className="col-md-6"
                            name="bankAccounts[0].accountCurrency"
                            label="Moneda"
                            required
                            noPlaceholder
                            disabled={bankAccountsQuantity === 2}
                          >
                            {currencies?.map((currency: InfoBlockField) => (
                              <option key={currency.value} value={currency.value}>
                                {capitalize(currency.name)}
                              </option>
                            ))}
                          </Select>
                          <Input
                            className="input col-md-6"
                            type="text"
                            name="bankAccounts[0].accountBranch"
                            label="N° de sucursal"
                            disabled={disableField('accountBranch', values.bankAccounts[0].bankExternalId)}
                          />
                          <Input
                            className="input col-md-6"
                            type="text"
                            name="bankAccounts[0].accountHolder"
                            label="Titular de la cuenta"
                            required
                          />
                          <Select
                            className="col-md-6"
                            name="bankAccounts[0].accountType"
                            label="Tipo de cuenta"
                            required
                          >
                            {bankAccountTypes?.map((accountType: AccountType) => (
                              <option key={accountType.value} value={accountType.value}>
                                {accountType.name}
                              </option>
                            ))}
                          </Select>
                          <Input
                            className="input col-md-6"
                            type="text"
                            name="bankAccounts[0].subAccount"
                            label="Subcuenta"
                            disabled={disableField('subAccount', values.bankAccounts[0].bankExternalId)}
                          />
                          <Select
                            className="col-md-6"
                            name="bankAccounts[0].isActive"
                            label="Cuenta activa"
                            required
                          >
                            <option value="true">Sí</option>
                            <option value="false">No</option>
                          </Select>

                          {bankAccountsQuantity === 2 && (
                            <>
                              <div className="title-row with-icon my-3">
                                <p className="input-subtitle">CUENTA BANCARIA 2</p>
                                <button
                                  className="delete-bank-account-button"
                                  aria-label="Eliminar cuenta bancaria"
                                  onClick={() => handleDeleteAccount(setFieldValue, values.bankAccounts)}
                                >
                                  <FontAwesomeIcon className="faTrashCan" icon={faTrashCan as IconProp} />
                                </button>
                              </div>
                              <Select
                                className="col-md-6"
                                name="bankAccounts[1].bankExternalId"
                                label="Banco"
                                required
                              >
                                {banks?.map((bank: Bank) => (
                                  <option key={bank.id} value={bank.id}>
                                    {bank.name}
                                  </option>
                                ))}
                              </Select>
                              <Input
                                className="input col-md-6"
                                type="text"
                                name="bankAccounts[1].accountNumber"
                                label="N° de Cuenta"
                                required
                              >
                                <small className="input-info">
                                  Especificaciones del número de cuenta según el banco seleccionado
                                </small>
                              </Input>
                              <Select
                                className="col-md-6"
                                name="bankAccounts[1].accountCurrency"
                                label="Moneda"
                                disabled
                              >
                                {currencies?.map(
                                  (currency: InfoBlockField) =>
                                    currency.value !== values.bankAccounts[0].accountCurrency && (
                                      <option key={currency.value} value={currency.value}>
                                        {capitalize(currency.name)}
                                      </option>
                                    ),
                                )}
                              </Select>
                              <Input
                                className="input col-md-6"
                                type="text"
                                name="bankAccounts[1].accountBranch"
                                label="N° de sucursal"
                                disabled={disableField(
                                  'accountBranch',
                                  values.bankAccounts[1].bankExternalId,
                                )}
                              />
                              <Input
                                className="input col-md-6"
                                type="text"
                                name="bankAccounts[1].accountHolder"
                                label="Titular de la cuenta"
                                required
                              />
                              <Select
                                className="col-md-6"
                                name="bankAccounts[1].accountType"
                                label="Tipo de cuenta"
                                required
                              >
                                {bankAccountTypes?.map((accountType: AccountType) => (
                                  <option key={accountType.value} value={accountType.value}>
                                    {accountType.name}
                                  </option>
                                ))}
                              </Select>
                              <Input
                                className="input col-md-6"
                                type="text"
                                name="bankAccounts[1].subAccount"
                                label="Subcuenta"
                                disabled={disableField('subAccount', values.bankAccounts[1].bankExternalId)}
                              />
                              <Select
                                className="col-md-6"
                                name="bankAccounts[1].isActive"
                                label="Cuenta activa"
                                required
                              >
                                <option value="true">Sí</option>
                                <option value="false">No</option>
                              </Select>
                            </>
                          )}
                        </>
                      )}
                      {showTrackNumberInput && (
                        <div>
                          <div className="title-row with-icon my-3">
                            <p className="input-subtitle">NUEVA CUENTA SANTANDER</p>
                            <button
                              className="delete-bank-account-button"
                              aria-label="Eliminar cuenta bancaria"
                              onClick={handleShowTrackNumber}
                            >
                              <FontAwesomeIcon className="faTrashCan" icon={faTrashCan as IconProp} />
                            </button>
                          </div>
                          <Input
                            className="input col-12"
                            type="text"
                            name="bankAccountTrackingNumber"
                            label="Número de Seguimiento"
                          />
                        </div>
                      )}
                      <div className="buttons-container">
                        <div className="add-buttons">
                          {!showTrackNumberInput && (
                            <Button onClick={handleShowTrackNumber} className="share-button add-button">
                              <FontAwesomeIcon icon={faPlus as IconProp} />
                              Solicitar Nueva Cuenta Santander
                            </Button>
                          )}
                          {bankAccountsQuantity === 1 && (
                            <Button
                              onClick={() => handleAddAccount(values.bankAccounts[0], setFieldValue)}
                              className="share-button add-button"
                            >
                              <FontAwesomeIcon icon={faPlus as IconProp} />
                              Agregar otra cuenta
                            </Button>
                          )}
                        </div>
                      </div>
                    </Row>
                    {bankAccountsQuantity > 0 && (
                      <ConfirmBankModal
                        show={showModal}
                        toggleModal={toggleModal}
                        handleNextStep={handleNextStep}
                        values={values}
                        prometeoValues={prometeoData}
                        prometeoError={prometeoError}
                      />
                    )}
                  </fieldset>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
      <NavigationFooter previousHandler={handleGoBack} nextHandler={handleSubmit} loading={loading} />
    </>
  );
}

export default CommerceBankAccount;

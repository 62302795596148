import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import BusinessLogo from '../../../assets/business.png';
import NavigationFooter from '../../../components/NavigationFooter/NavigationFooter';
import { commerceBranchHelper } from '../../../helpers/commerceBranch.helper';
import { deleteBranchOffice, getBranchOffice } from '../../../services/commerce.service';
import { CommerceBranchesState } from '../../../types/add_commerce.types';
import CreateBranchSchemaType from '../../../types/branchOffice/branch-office-form';
import { BranchOfficeList } from '../../../types/branchOffice/request';
import { BranchAddressData, BranchTerminals } from '../../../types/branchOffice/response';
import { triggerToasts } from '../../../utils/helpers';
import { pagesPaths } from '../../../utils/navigationUtils';
import BranchesList from './BranchesList/BranchesList';
import BranchForm from './BranchForm';

function CommerceBranches() {
  const location = useLocation();
  const navigate = useNavigate();
  const localState: CommerceBranchesState = location.state as CommerceBranchesState;

  const [branches, setBranches] = useState<BranchOfficeList[]>([]);
  const [showBranchForm, setShowBranchForm] = useState<boolean>(true);
  const [selectedBranchIndex, setSelectedBranchIndex] = useState<number>(0);
  const [branchData, setBranchData] = useState<CreateBranchSchemaType>();
  const [presettedTerminals, setPresettedTerminals] = useState<BranchTerminals[]>();
  const [branchAddressData, setBranchAddressData] = useState<BranchAddressData>();

  const handleGoBack = () => navigate(pagesPaths.CommerceBankAccount, { state: { ...localState } });

  useEffect(() => {
    const branchesList = localState.branches.map((branch) => {
      return { id: branch.id!, fantasyName: branch.fantasyName };
    });

    if (branchesList.length) {
      setShowBranchForm(false);
    }

    setBranches(branchesList);
  }, []);

  const showBranchFormHandler = () => {
    setShowBranchForm(true);
  };

  const deleteBranchOfficeHandler = async (branchOfficeId: number) => {
    await deleteBranchOffice(branchOfficeId, localState.id!);

    setBranches((prevValues) => {
      return prevValues.filter((branch) => branch.id !== branchOfficeId);
    });

    localState.branches = localState.branches.filter((branch) => branch.id !== branchOfficeId);
  };

  const fetchBranch = async (branchOfficeId: number) => {
    const response = await getBranchOffice(branchOfficeId, localState.id!);
    const branchOfficeValues = response.data;
    const branchFormValues = commerceBranchHelper.buildBranchFormValues(branchOfficeValues);

    setBranchData(branchFormValues);
    setPresettedTerminals(branchOfficeValues.terminals);
    setBranchAddressData({
      city: branchOfficeValues.city,
      state: branchOfficeValues.city.state!,
      neighborhood: undefined,
    });
  };

  const editBranchHandler = async (branchId: number, branchIndex: number) => {
    await fetchBranch(branchId);

    setSelectedBranchIndex(branchIndex);
    setShowBranchForm(true);
  };

  const nextHandler = () => {
    if (branches.length) {
      return navigate(pagesPaths.CommerceTerminalsShipping, { state: { ...localState } });
    }

    triggerToasts('Debes cargar al menos una sucursal para continuar', false);
  };

  return (
    <div className="branches-container">
      <Container className="branches">
        <ProgressBar now={(100 / 8) * 4} />
        <Row>
          <Col lg={12}>
            <h1>
              <img src={BusinessLogo} alt="Comercio" className="me-3" /> Configuración de Sucursales
            </h1>
          </Col>
          <br />
          <Col lg={3}>
            <BranchesList
              branches={branches}
              deleteBranchOffice={deleteBranchOfficeHandler}
              editBranch={editBranchHandler}
            />
          </Col>
          <Col lg={9}>
            {!showBranchForm ? (
              <div className="add-branch-container">
                <div className="add-branch-text-container">
                  <h3>NUEVA SUCURSAL</h3>
                  <h4>Agrega nuevas sucursales o presiona "Continuar” si ya has terminado. </h4>
                </div>
                <div className="add-branch-button-container">
                  <Button onClick={showBranchFormHandler} className="add-section">
                    <FontAwesomeIcon icon={faPlus as IconProp} />
                    Agregar nueva sucursal
                  </Button>
                </div>
              </div>
            ) : (
              <BranchForm
                setBranches={setBranches}
                setShowBranchForm={setShowBranchForm}
                branchData={branchData}
                isInEditMode={!!branchData}
                branchIndex={selectedBranchIndex}
                branchAddressData={branchAddressData}
                presettedTerminals={presettedTerminals}
                setBranchData={setBranchData}
                setPresettedTerminals={setPresettedTerminals}
                setBranchAddressData={setBranchAddressData}
              />
            )}
          </Col>
        </Row>
      </Container>
      <NavigationFooter previousHandler={handleGoBack} nextHandler={nextHandler} />
    </div>
  );
}

export default CommerceBranches;

import {
  getBusinessCategories,
  getBusinessTypes,
  getCities,
  getStates,
  getTaxPayers,
} from '../services/commerce.service';
import { getTea } from '../services/tea.service';
import { BusinessCategory, City, State, TaxPayer } from '../types/add_commerce.types';
import { BusinessType } from '../utils/data';
import { sortArrayAlphabetically } from '../utils/helpers';

const fetchStates = async (setStates: React.Dispatch<React.SetStateAction<State[]>>) => {
  const responseStates = await getStates();

  setStates(responseStates);
};

const fetchCities = async (stateId: number, setCities: React.Dispatch<React.SetStateAction<City[]>>) => {
  const responseCities = await getCities(stateId);
  setCities(responseCities.data);
};

const fetchTea = async (setTea: React.Dispatch<React.SetStateAction<string>>) => {
  const tea = await getTea();

  setTea(tea);
};

const fetchBusinessCategories = async (
  setBusinessCategories: React.Dispatch<React.SetStateAction<BusinessCategory[]>>,
) => {
  const responseBusinessCategories = await getBusinessCategories();
  if (Array.isArray(responseBusinessCategories.data)) {
    const sortedResponse = sortArrayAlphabetically(responseBusinessCategories.data, 'name');
    setBusinessCategories(sortedResponse);
  }
};

const fetchTaxPayers = async (setTaxPayers: React.Dispatch<React.SetStateAction<TaxPayer[]>>) => {
  const responseTaxPayers = await getTaxPayers();
  if (Array.isArray(responseTaxPayers.data)) {
    const sortedResponse = sortArrayAlphabetically(responseTaxPayers.data, 'description');
    setTaxPayers(sortedResponse);
  }
};

const fetchBusinessTypes = async (
  businessCategoryId: number,
  setBusinessTypes: React.Dispatch<React.SetStateAction<BusinessType[]>>,
) => {
  const serviceResponse = await getBusinessTypes(businessCategoryId);

  setBusinessTypes(serviceResponse.data);
};

export const commerceAddressHelper = {
  fetchStates,
  fetchCities,
  fetchTea,
  fetchBusinessCategories,
  fetchTaxPayers,
  fetchBusinessTypes,
};

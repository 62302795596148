import {
  InfoBlockField,
  NewBeneficiaryValues,
  NewBranchValues,
  NewBranchValuess,
  NewTerminalValues,
} from '../types/add_commerce.types';
import { BankAccountTypesEnum } from '../types/enums/bank-account-types.enum';
import { CardBrands } from '../types/enums/card-brands.enum';
import { CurrencyEnum } from '../types/enums/currency.enum';
import { DocumentTypeEnum } from '../types/enums/document-type.enum';
import { LawsEnum } from '../types/enums/law.enum';
import { PhoneCountryCodesEnum } from '../types/enums/phone-country-codes.enum';
import { TerminalTypesEnum } from '../types/enums/terminal-types.enum';

export interface Bank {
  id: string;
  name: string;
  lengths: number | number[] | null;
}

export interface LawFormField {
  label: string;
  fieldname: string;
  value: LawsEnum;
}

export interface AccountType {
  name: string;
  value: BankAccountTypesEnum;
}

export interface BusinessType {
  name: string;
  value: number;
}

export interface CurrencyDictionary {
  [CurrencyEnum.URUGUAYAN_PESOS]: string;
  [CurrencyEnum.US_DOLLAR]: string;
}

export const BROU_BANK_ID = '50';
export const SANTANDER_BANK_ID = '55';
export const BANDES_BANK_ID = '56';
export const PROVINCIA_BANK_ID = '59';
export const HERITAGE_BANK_ID = '60';
export const PREX_BANK_ID = '61';
export const BHU_BANK_ID = '62';

// NOTE: PREX, BANCO NACION and BANCO PROVINCIA are not included yet by order of the client
export const banks: Bank[] = [
  { id: BROU_BANK_ID, name: 'BROU', lengths: 14 },
  { id: '51', name: 'CITIBANK', lengths: 10 },
  { id: '52', name: 'SCOTIABANK', lengths: 10 },
  { id: '53', name: 'ITAÚ', lengths: 7 },
  { id: '54', name: 'BBVA', lengths: 9 },
  { id: SANTANDER_BANK_ID, name: 'SANTANDER', lengths: 12 },
  { id: BANDES_BANK_ID, name: 'BANDES', lengths: 9 },
  // { id: '57', name: 'BANCO NACIÓN AR', lengths: 12 },
  { id: '58', name: 'HSBC', lengths: 10 },
  // { id: PROVINCIA_BANK_ID, name: 'BANCO PROVINCIA AR', lengths: null },
  { id: HERITAGE_BANK_ID, name: 'HERITAGE', lengths: 9 },
  // { id: PREX_BANK_ID, name: 'PREX', lengths: null },
  { id: BHU_BANK_ID, name: 'BHU', lengths: 12 },
];

const SELECCIONAR_DEFAULT_OPTION = '';

export const banksWithBranchAccount: string[] = [
  SELECCIONAR_DEFAULT_OPTION,
  SANTANDER_BANK_ID,
  BANDES_BANK_ID,
  PROVINCIA_BANK_ID,
  PREX_BANK_ID,
  BHU_BANK_ID,
];

export const banksWithSubaccount: string[] = [
  SELECCIONAR_DEFAULT_OPTION,
  BROU_BANK_ID,
  PROVINCIA_BANK_ID,
  HERITAGE_BANK_ID,
  PREX_BANK_ID,
  BHU_BANK_ID,
];

export const bankAccountTypes: AccountType[] = [
  { name: 'Cuenta Corriente', value: BankAccountTypesEnum.CUENTA_CORRIENTE },
  { name: 'Caja de Ahorro', value: BankAccountTypesEnum.CAJA_DE_AHORRO },
];

export const terminalNetworkOptions: InfoBlockField[] = [
  { name: 'New Age Data (NAD)', value: TerminalTypesEnum.NAD },
  { name: 'POS2000', value: TerminalTypesEnum.POS2000 },
  { name: 'Scanntech', value: TerminalTypesEnum.SCANNTECH },
  { name: 'Resonance', value: TerminalTypesEnum.RESONANCE },
  { name: 'Handy', value: TerminalTypesEnum.HANDY },
  { name: 'Sisteco', value: TerminalTypesEnum.SISTECO },
  { name: 'Geocom', value: TerminalTypesEnum.GEOCOM },
  { name: 'Pranasys', value: TerminalTypesEnum.PRANASYS },
  { name: 'Red Fiserv', value: TerminalTypesEnum.FISERV },
  { name: 'Red Oca', value: TerminalTypesEnum.OCA },
  { name: 'Red Visanet', value: TerminalTypesEnum.VISANET },
];

export const laws: InfoBlockField[] = [
  { name: 'Ley 19.210', value: LawsEnum.law_19210.toString() },
  { name: 'Ley 17.934 (Restaurantes)', value: LawsEnum.law_17934.toString() },
  { name: 'Ley 18.083 (IMESI)', value: LawsEnum.law_18083.toString() },
  { name: 'Ley 18.910 (AFAM)', value: LawsEnum.law_18910.toString() },
];

export const lawsFormFields: LawFormField[] = [
  { label: laws[0].name, fieldname: 'law_19210', value: LawsEnum.law_19210 },
  { label: laws[1].name, fieldname: 'law_17934', value: LawsEnum.law_17934 },
  { label: laws[2].name, fieldname: 'law_18083', value: LawsEnum.law_18083 },
  { label: laws[3].name, fieldname: 'law_18910', value: LawsEnum.law_18910 },
];

export const basicInfoFields: string[] = [
  'Nº de RUT',
  'Nombre fantasía',
  'Razón social',
  'Nombre y apellidos de contacto',
  'Teléfono',
  'Celular',
  'Email',
  'Facturación promedio mensual ($UY)',
  'Canal de venta',
];

export const branchFields = [
  'Nombre Fantasía',
  'Departamento',
  'Localidad',
  'Dirección',
  'Apto',
  'Solar',
  'Manzana',
];

export const beneficiaryFields = [
  'Tipo de documento',
  'Nº de Documento',
  'Nombre y apellido',
  '% de participación',
  'Es PEP',
  'Es Familiar',
];

export const mainAddressFields: string[] = [
  'Departamento',
  'Localidad',
  'Dirección',
  'Apto',
  'Solar',
  'Manzana',
];

export const bankAccountFields: string[] = [
  'Banco',
  'Nº de cuenta',
  'Moneda',
  'Nº de sucursal',
  'Titular de la cuenta',
];

export const salesOperationFields: InfoBlockField[] = [{ name: 'Venta con tarjeta presente', value: '-' }];

export const economicActivityFields: string = 'Rubro';
export const businessPlansFields: string = 'Moneda';
export const tributaryInfoFields: string = 'Tipo de contribuyente';

export const documentTypes: InfoBlockField[] = [
  { name: 'Cédula de Identidad', value: DocumentTypeEnum.CI },
  { name: 'Pasaporte', value: DocumentTypeEnum.PASSPORT },
];

export const currencies: InfoBlockField[] = [
  { name: 'PESOS URUGUAYOS', value: CurrencyEnum.URUGUAYAN_PESOS },
  { name: 'DÓLAR ESTADOUNIDENSE', value: CurrencyEnum.US_DOLLAR },
];

export const currencyDictionary: CurrencyDictionary = {
  [CurrencyEnum.URUGUAYAN_PESOS]: 'Pesos Uruguayos',
  [CurrencyEnum.US_DOLLAR]: 'Dólar Estadounidense',
};

export const newBeneficiary: NewBeneficiaryValues = {
  documentType: null,
  documentNumber: '',
  firstName: '',
  lastName: '',
  participationPercentage: null,
  branchOfficeId: null,
  isPep: false,
  isRelative: false,
  backDocument: null,
  frontDocument: null,
  beneficiary_branch_office: null,
};

export const newBranchh: NewBranchValuess = {
  fantasyName: '',
  businessCategoryId: undefined,
  state: undefined,
  city: undefined,
  neighborhood: undefined,
  addressStreet: '',
  addressNumber: undefined,
  addressFlatNumber: undefined,
  addressSolar: undefined,
  addressPlot: undefined,
};

export const newBranch: NewBranchValues = {
  fantasyName: '',
  state: undefined,
  city: undefined,
  neighborhood: undefined,
  addressStreet: '',
  addressNumber: undefined,
  addressFlatNumber: undefined,
  addressSolar: undefined,
  addressPlot: undefined,
};

export const conditionsAndTariffStandarData = [
  {
    title: 'TARJETA DE CRÉDITO',
    headings: ['Condiciones', 'Venta con tarjeta local'],
    rows: [
      { plan: '3 pagos', currency: 'Dólares/Pesos uruguayos', fees: '4.80%', deferred: '52 días' },
      { plan: '4 pagos', currency: 'Dólares/Pesos uruguayos', fees: '4.80%', deferred: '67 días' },
      { plan: '5 pagos', currency: 'Dólares/Pesos uruguayos', fees: '4.80%', deferred: '82 días' },
      { plan: '6 pagos', currency: 'Dólares/Pesos uruguayos', fees: '4.80%', deferred: '90 días' },
      {
        plan: 'Combustible / 1 pago',
        currency: 'Dólares/Pesos uruguayos',
        fees: '3.75%',
        deferred: '2 días',
      },
    ],
  },
  {
    title: 'TARJETA DE CRÉDITO',
    headings: ['Condiciones', 'Venta con tarjeta internacional'],
    rows: [
      { plan: '1 pago', currency: 'Dólares/Pesos uruguayos', fees: '4.40%', deferred: '2 días' },
      {
        plan: 'Combustible / 1 pago',
        currency: 'Dólares/Pesos uruguayos',
        fees: '4.40%',
        deferred: '2 días',
      },
    ],
  },
  {
    title: 'TARJETA DÉBITO-DOMÉSTICO',
    headings: ['Condiciones', 'Venta con tarjeta local'],
    rows: [
      { plan: '1 pago', currency: 'Dólares/Pesos uruguayos', fees: '1.13%', deferred: '1 día' },
      {
        plan: 'Combustible / 1 pago',
        currency: 'Dólares/Pesos uruguayos',
        fees: '0.99%',
        deferred: '67 días',
      },
    ],
  },
  {
    title: 'TARJETA PREPAGA-DOMÉSTICO',
    headings: ['Condiciones', 'Venta con tarjeta local'],
    rows: [
      { plan: '1 pago', currency: 'Dólares/Pesos uruguayos', fees: '1.18%', deferred: '1 día' },
      { plan: 'Combustible / 1 pago', currency: 'Dólares/Pesos uruguayos', fees: '1.18%', deferred: '1 día' },
    ],
  },
  {
    title: 'TARJETA DE DÉBITO MC/MAESTRO/PREPAGA',
    headings: ['Condiciones', 'Venta con tarjeta local'],
    rows: [
      { plan: '1 pago', currency: 'Dólares/Pesos uruguayos', fees: '1.18%', deferred: '1 día' },
      { plan: 'Combustible / 1 pago', currency: 'Dólares/Pesos uruguayos', fees: '1.18%', deferred: '1 día' },
    ],
  },
];

export const conditionsAndTariffPersonalaizedMastercardData = [
  {
    rows: [
      { plan: 'DÉBITO', currency: 1.12, suffix: '%', fees: 1.12, deferred: 1.06 },
      { plan: 'PREPAGO', currency: 1.17, suffix: '%', fees: 1.17, deferred: 1.11 },
      { plan: 'CRÉDITO CONTADO', currency: 3.35, suffix: '%', fees: 3.35, deferred: 3.18 },
      { plan: 'CRÉDITO CUOTAS', currency: 3.92, suffix: '%', fees: 3.92, deferred: 3.72 },
      {
        plan: 'Extranjeros DÉBITO',
        currency: 3.4,
        suffix: '%',
        fees: 3.4,
        deferred: 3.4,
      },
      {
        plan: 'Extranjeros CRÉDITO',
        currency: 3.4,
        suffix: '%',
        fees: 3.4,
        deferred: 3.4,
      },
    ],
  },
];

export const conditionsAndTariffPersonalaizedVisaData = [
  {
    rows: [
      { plan: 'DÉBITO', currency: 1.05, suffix: '%', fees: 1.12, deferred: 0.99 },
      { plan: 'PREPAGO', currency: 1.1, suffix: '%', fees: 1.1, deferred: 1.04 },
      { plan: 'CRÉDITO CONTADO', currency: 3.15, suffix: '%', fees: 3.15, deferred: 2.96 },
      { plan: 'CRÉDITO CUOTAS', currency: 4.44, suffix: '%', fees: 4.44, deferred: 4.17 },
      {
        plan: 'Extranjeros DÉBITO',
        currency: 3.4,
        suffix: '%',
        fees: 3.4,
        deferred: 3.4,
      },
      {
        plan: 'Extranjeros CRÉDITO',
        currency: 3.4,
        suffix: '%',
        fees: 3.4,
        deferred: 3.4,
      },
    ],
  },
];

export const standarComisionHeadings: string[] = ['Plan de pago', 'Moneda', 'Comisión', 'Diferido pago'];

export const standarComisionVisaHeadings: string[] = ['Propuesta VISA', 'Arancel'];

export const standarComisionMasterHeadings: string[] = ['Propuesta MASTER', 'Arancel'];

export const masterPersonalizedComisionHeadings: string[] = [
  'Propuesta MASTER',
  'Arancel personalizado',
  'Arancel sugerido',
  'Arancel mínimo',
];

export const visaPersonalizedComisionHeadings: string[] = [
  'Propuesta VISA',
  'Arancel personalizado',
  'Arancel sugerido',
  'Arancel mínimo',
];

export const terminalHeadings: string[] = ['Producto', 'Cantidad', 'Valor UI', 'Precio en pesos'];

export const newTerminal: NewTerminalValues = { type: '', number: null };

export const COMMENT_MAX_LENGTH: number = 250;

export const integrationType: { value: number; name: string }[] = [
  { value: 1, name: 'Web CheckOut' },
  { value: 6, name: 'API' },
  { value: 4, name: 'QR' },
  { value: 5, name: 'Link de pago' },
];

export const integrations: { value: string; label: string }[] = [
  { value: '0', label: 'No' },
  { value: '64', label: 'ABECH' },
  { value: '41', label: 'Avance Informática' },
  { value: '73', label: 'BAS' },
  { value: '42', label: 'Bit' },
  { value: '19', label: 'Boticario' },
  { value: '66', label: 'CDSOFT' },
  { value: '9', label: 'ContaWIN' },
  { value: '49', label: 'Cualit' },
  { value: '6', label: 'Designware' },
  { value: '45', label: 'ePharma' },
  { value: '58', label: 'EURESIS' },
  { value: '30', label: 'Eximia' },
  { value: '101', label: 'FacturaLista' },
  { value: '68', label: 'FACTURAYA' },
  { value: '83', label: 'GESTCOM (GARGANO)' },
  { value: '84', label: 'GNS' },
  { value: '48', label: 'GSOFT' },
  { value: '4', label: 'ICG' },
  { value: '39', label: 'InfoFarma' },
  { value: '20', label: 'Infra Sistemas' },
  { value: '43', label: 'Insis' },
  { value: '56', label: 'IPARKINGS' },
  { value: '102', label: 'ISA IjServ' },
  { value: '32', label: 'IT Sistemas' },
  { value: '3', label: 'Macrosoft' },
  { value: '38', label: 'Magma ERP' },
  { value: '86', label: 'MONTEVIDEO COMM' },
  { value: '67', label: 'MONTEVIDEO SOFTWARE' },
  { value: '72', label: 'NODUM' },
  { value: '7', label: 'POSCOMM' },
  { value: '2', label: 'Premium' },
  { value: '85', label: 'PRESENTIA - Progestion' },
  { value: '44', label: 'Prosys' },
  { value: '26', label: 'PSIG' },
  { value: '95', label: 'QFACTURA' },
  { value: '8', label: 'Real2b' },
  { value: '78', label: 'SER-TEC' },
  { value: '80', label: 'SERVIMATICA - Totem' },
  { value: '93', label: 'SIGMA SHOP' },
  { value: '5', label: 'Sisnet' },
  { value: '103', label: 'SOFT RESTAURANT' },
  { value: '50', label: 'Software 332' },
  { value: '104', label: 'Surbit DelySoft' },
  { value: '34', label: 'Urusys' },
  { value: '105', label: 'WorkSoft' },
  { value: '40', label: 'Zeta Libra' },
  { value: '106', label: 'Otros' },
];

// TODO: provisional solution for coexistence of initial integrators and integrators modification requested
export const initialIntegratorsNotCurrentlyIncluded: { value: string; label: string }[] = [
  { value: '1', label: 'Visual SStoe' },
  { value: '10', label: 'Prosis Ltda' },
  { value: '11', label: 'Sistemas FerryLineas' },
  { value: '12', label: 'Synthesis' },
  { value: '13', label: 'Daniel Dupre' },
  { value: '14', label: 'Mauricio Pera' },
  { value: '15', label: 'Senda Cinema' },
  { value: '17', label: 'Luis Cruz' },
  { value: '18', label: 'Libros Libros' },
  { value: '21', label: 'Calculo' },
  { value: '22', label: 'Cofas' },
  { value: '23', label: 'SuperStore' },
  { value: '24', label: 'TOTO Calzados' },
  { value: '25', label: 'GRUPOT' },
  { value: '27', label: 'Jorge W. Ganón' },
  { value: '28', label: 'Floreal Perez' },
  { value: '29', label: 'ISA' },
  { value: '31', label: 'Enrique Grant' },
  { value: '33', label: 'Numina' },
  { value: '35', label: 'LOGIKOM' },
  { value: '36', label: 'DelySoft' },
  { value: '37', label: 'GestionFacil' },
  { value: '46', label: 'Giordano' },
  { value: '47', label: 'IMS Software' },
  { value: '51', label: 'Alberto Ganduglia' },
  { value: '52', label: 'NAD Caja' },
  { value: '53', label: 'DIEBOLD H&M' },
  { value: '54', label: 'CHAIA' },
  { value: '55', label: 'UCOT' },
  { value: '57', label: 'ENTERTEC' },
  { value: '59', label: 'ARNALDO CASTRO' },
  { value: '60', label: 'TBLSOFTWARE' },
  { value: '61', label: 'QUARXS' },
  { value: '62', label: 'Bit Cierre Lote Centralizado' },
  { value: '63', label: 'CLAUDIO ENRIQUE LUNA MARIN' },
  { value: '65', label: 'INVENZIS' },
  { value: '69', label: 'LAVOMAT' },
  { value: '70', label: 'PARALELO' },
  { value: '71', label: 'PUNTOEXE' },
  { value: '74', label: 'ASISTENCIAL MEDICA' },
  { value: '75', label: 'ClienteT4 API' },
  { value: '76', label: 'SAO' },
  { value: '77', label: 'ICG StandAlone NAD' },
  { value: '79', label: 'SANTA CLARA' },
  { value: '81', label: 'NAPSE' },
  { value: '82', label: 'POWER STREET' },
  { value: '87', label: 'DVELOP' },
  { value: '88', label: 'DISTRIBUIDORA SAN ANTONIO' },
  { value: '89', label: 'SILICOMP' },
  { value: '90', label: 'ICG IT' },
  { value: '91', label: 'GPSoftware' },
  { value: '92', label: 'RESTOSOFT' },
  { value: '94', label: 'ZONA TECNO' },
  { value: '96', label: 'SGCPYME' },
  { value: '97', label: 'AYP SOFT S.R.L' },
  { value: '98', label: 'PVP' },
  { value: '99', label: 'ITSISTEMAS' },
  { value: '100', label: 'SERVIMATICA' },
];

export const countriesPhoneCodes = [
  { label: 'AR', value: PhoneCountryCodesEnum.AR },
  { label: 'UY', value: PhoneCountryCodesEnum.UY },
  { label: 'BR', value: PhoneCountryCodesEnum.BR },
  { label: 'PY', value: PhoneCountryCodesEnum.PY },
];

export const commerceCodesCardBrands = [
  { label: 'AMEX', value: CardBrands.AMEX },
  { label: 'ANDA', value: CardBrands.ANDA },
  { label: 'CABAL', value: CardBrands.CABAL },
  { label: 'CABAL DÓLARES', value: CardBrands.CABAL_DOLARES },
  { label: 'CLUB DEL ESTE', value: CardBrands.CLUB_DEL_ESTE },
  { label: 'CREDITEL', value: CardBrands.CREDITEL },
  { label: 'LIDER/DINERS', value: CardBrands.LIDER_DINERS },
  { label: 'OCA PRODUCTOS', value: CardBrands.OCA_PRODUCTOS },
  { label: 'PASSCARD', value: CardBrands.PASSCARD },
  { label: 'TARJETA D', value: CardBrands.TARJETA_D },
];

export const commerceCodesCardBrandsDictionary = {
  [CardBrands.AMEX]: 'amex',
  [CardBrands.ANDA]: 'anda',
  [CardBrands.CABAL]: 'cabal',
  [CardBrands.CABAL_DOLARES]: 'cabalDolar',
  [CardBrands.CLUB_DEL_ESTE]: 'clubDelEste',
  [CardBrands.CREDITEL]: 'creditel',
  [CardBrands.LIDER_DINERS]: 'diners',
  [CardBrands.OCA_PRODUCTOS]: 'oca',
  [CardBrands.PASSCARD]: 'passcard',
  [CardBrands.TARJETA_D]: 'tarjetaD',
};

export const commerceCodesDictionary = {
  code: {
    [CardBrands.AMEX]: 'amexCode',
    [CardBrands.ANDA]: 'andaCode',
    [CardBrands.CABAL]: 'cabalCode',
    [CardBrands.CABAL_DOLARES]: 'cabalDolarCode',
    [CardBrands.CLUB_DEL_ESTE]: 'clubDelEsteCode',
    [CardBrands.CREDITEL]: 'creditelCode',
    [CardBrands.LIDER_DINERS]: 'dinersCode',
    [CardBrands.OCA_PRODUCTOS]: 'ocaCode',
    [CardBrands.PASSCARD]: 'passcardCode',
    [CardBrands.TARJETA_D]: 'tarjetaDCode',
  },
  acquirer: {
    [CardBrands.AMEX]: 'amexAcquirer',
    [CardBrands.ANDA]: 'andaAcquirer',
    [CardBrands.CABAL]: 'cabalAcquirer',
    [CardBrands.CABAL_DOLARES]: 'cabalDolarAcquirer',
    [CardBrands.CLUB_DEL_ESTE]: 'clubDelEsteAcquirer',
    [CardBrands.CREDITEL]: 'creditelAcquirer',
    [CardBrands.LIDER_DINERS]: 'dinersAcquirer',
    [CardBrands.OCA_PRODUCTOS]: 'ocaAcquirer',
    [CardBrands.PASSCARD]: 'passcardAcquirer',
    [CardBrands.TARJETA_D]: 'tarjetaDAcquirer',
  },
};

export const commerceCodesCardBrandsArray = [
  CardBrands.AMEX,
  CardBrands.ANDA,
  CardBrands.CABAL,
  CardBrands.CABAL_DOLARES,
  CardBrands.CLUB_DEL_ESTE,
  CardBrands.CREDITEL,
  CardBrands.LIDER_DINERS,
  CardBrands.OCA_PRODUCTOS,
  CardBrands.PASSCARD,
  CardBrands.TARJETA_D,
];

export const couriers = [
  { value: 'PedidosYa', label: 'Pedidos Ya' },
  { value: 'DAC', label: 'DAC' },
  { value: 'Districar', label: 'Districar' },
  { value: 'DePunta', label: 'DePunta' },
];

export const deliveryTimes = [
  { value: '10-18hs', label: '10 a 18 hs' },
  { value: '10-12hs', label: '10 a 12 hs' },
  { value: '15-18hs', label: '15 a 18 hs' },
];

import { Container } from 'react-bootstrap';
import { BranchOfficeList } from '../../../../types/branchOffice/request';
import BranchListItem from './BranchListItem/BranchListItem';

interface BranchesListProps {
  branches: BranchOfficeList[];
  deleteBranchOffice: (branchOfficeId: number) => void;
  editBranch: (branchOfficeId: number, branchIndex: number) => void;
}

function BranchesList({ branches, deleteBranchOffice, editBranch }: BranchesListProps) {
  return (
    <Container className="branches-list-container">
      <div className="branches-list">
        <div className="branches-list-title-container">
          <h3 className="branches-list-title">Listado de sucursales</h3>
        </div>
        <div className="branches-list-data-container">
          {branches.length ? (
            <div className="branches-list-items-container">
              {branches.map((branch, index) => (
                <BranchListItem
                  key={branch.id}
                  branch={branch}
                  branchNumber={index + 1}
                  deleteBranchOffice={deleteBranchOffice}
                  editBranch={editBranch}
                />
              ))}
            </div>
          ) : (
            <div className="branches-list-no-data-text-container">
              <h3>No tienes sucursales</h3>
              <p>Cuando agregues las sucursales podrás visualizarlas aquí.</p>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
}

export default BranchesList;

import { Controller, useFormContext } from 'react-hook-form';

interface RoundedCheckboxProps {
  label: string;
  value: string;
  name: string;
  customClass?: string;
  currentComboCheckboxValue: string;
  onChange?: (value: string) => void;
}

function RHFRoundedCheckbox({
  label,
  value,
  name,
  customClass,
  currentComboCheckboxValue,
  onChange,
}: RoundedCheckboxProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className={`form-check form-check-inline ${customClass}`}>
          <input
            className="form-check-input radio-button"
            type="radio"
            id={name}
            {...field}
            onChange={(e) => {
              onChange ? onChange(e.target.value) : field.onChange(e);
            }}
            value={value}
            checked={value === currentComboCheckboxValue}
          />
          <label className="form-check-label" htmlFor={name}>
            {label}
          </label>
        </div>
      )}
    />
  );
}

export default RHFRoundedCheckbox;

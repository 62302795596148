import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { commerceBranchHelper } from '../../../helpers/commerceBranch.helper';
import { upsertBranchOffice } from '../../../services/commerce.service';
import { CommerceAddressState } from '../../../types/add_commerce.types';
import CreateBranchSchemaType from '../../../types/branchOffice/branch-office-form';
import { BranchOfficeList, CreateBranchValues } from '../../../types/branchOffice/request';
import { BranchAddressData, BranchTerminals } from '../../../types/branchOffice/response';
import { ConditionsTariffEnum } from '../../../types/enums/conditions-tariff.enum';
import schemas from '../../../utils/schemas';
import BranchBankAccount from './BranchBankAccount/BranchBankAccount';
import BranchBasicInfo from './BranchBasicInfo/BranchBasicInfo';
import BranchProducts from './BranchProducts/BranchProducts';
import BranchTariff from './BranchTariff/BranchTariff';

interface BranchFormProps {
  branchData?: CreateBranchSchemaType;
  presettedTerminals?: BranchTerminals[];
  branchAddressData?: BranchAddressData;
  branchIndex?: number;
  isInEditMode: boolean;
  setBranches: React.Dispatch<React.SetStateAction<BranchOfficeList[]>>;
  setShowBranchForm: React.Dispatch<React.SetStateAction<boolean>>;
  setBranchData: React.Dispatch<React.SetStateAction<CreateBranchSchemaType | undefined>>;
  setPresettedTerminals: React.Dispatch<React.SetStateAction<BranchTerminals[] | undefined>>;
  setBranchAddressData: React.Dispatch<React.SetStateAction<BranchAddressData | undefined>>;
}

function BranchForm({
  branchData,
  setBranches,
  setShowBranchForm,
  presettedTerminals,
  branchAddressData,
  setPresettedTerminals,
  setBranchAddressData,
  setBranchData,
  branchIndex,
  isInEditMode,
}: BranchFormProps) {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const localState = location.state as CommerceAddressState;
  const resolver = yupResolver(schemas.CommerceBranchSchema);
  const defaultValues: CreateBranchSchemaType = branchData
    ? branchData
    : {
        id: null,
        fantasyName: '',
        addressStreet: '',
        addressNumber: '',
        addressFlatNumber: '',
        addressSolar: '',
        addressPlot: '',
        businessSubCategory: 0,
        city: '',
        state: '',
        hasNadProducts: false,
        nadProducts: {
          hasPosSale: false,
          hasImSantander: false,
          integration: '0',
          hasPixPayments: false,
          hasAmex: false,
          terminals: [],
          terminalPlan: {
            allowInstallments: '',
            includesTips: '',
            ivaType: '',
            commerceCodes: [],
          },
        },
        hasNewBankAccount: false,
        bankAccounts: [],
        hasPar: false,
        hasNonPresenceCard: false,
        hasEcommerce: false,
        ecommerce: { integrationType: [], processor: '' },
        tariff: { tariffType: ConditionsTariffEnum.STANDARD, acceptMaster: 'yes', acceptVisa: 'yes' },
      };

  const form = useForm({ defaultValues, resolver, mode: 'onChange' });
  const { handleSubmit } = form;

  const submitBranchOffice = handleSubmit(async (values) => {
    setIsLoading(true);

    const requestBody: CreateBranchValues = commerceBranchHelper.buildBranchRequest(values);
    const response = await upsertBranchOffice(requestBody, localState.id!);
    const branches = response.data.businessData.branches.map((branch: any) => {
      return { id: branch.id, fantasyName: branch.fantasyName };
    });

    setBranches(branches);
    setPresettedTerminals(undefined);
    setBranchAddressData(undefined);
    setBranchData(undefined);
    setShowBranchForm(false);
    setIsLoading(false);
  });

  return (
    <>
      <div className="branch-form-title-container">
        <div>
          <h3 className="branch-form-title">{isInEditMode ? `Sucursal ${branchIndex}` : 'NUEVA SUCURSAL'}</h3>
          {isInEditMode && <p>{branchData?.fantasyName}</p>}
        </div>
        {isInEditMode && (
          <div className="branch-edit-text-container">
            <p className="branch-edit-text">Editando sucursal</p>
          </div>
        )}
      </div>
      <FormProvider {...form}>
        <form onSubmit={submitBranchOffice}>
          <BranchBasicInfo branchData={branchAddressData} />
          <BranchBankAccount bankAccountIndex={0} />
          <BranchProducts presettedTerminals={presettedTerminals} />
          <BranchTariff />
          <div className="branch-submit-button-container">
            <Button type="submit" className="share-button add-button" disabled={isLoading}>
              <FontAwesomeIcon icon={faPlus as IconProp} />
              {isInEditMode ? 'Editar sucursal' : 'Agregar sucursal al listado'}
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
}

export default BranchForm;

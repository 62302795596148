import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Col, Container, ProgressBar, Row } from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate } from 'react-router-dom';
import POSIcon from '../../../assets/POS.svg';
import PromotionIcon from '../../../assets/promotion-icon.svg';
import InfoBlock from '../../../components/BusinessInfo/InfoBlock';
import Input from '../../../components/Input/Input';
import RoundedCheckbox from '../../../components/Input/RadioButton';
import Select from '../../../components/Input/Select';
import Switch from '../../../components/Input/Switch';
import NavigationFooter from '../../../components/NavigationFooter/NavigationFooter';
import { personalizedTariffHelper } from '../../../helpers/personalizedTariff.helper';
import { businessSectorService } from '../../../services/business-sector-table';
import { getCities, getStates, saveTerminalShippingInformation } from '../../../services/commerce.service';
import { City, CustomTableChunk, State } from '../../../types/add_commerce.types';
import { TerminalShippingDataRequest, TerminalShippingValues } from '../../../types/business/request';
import { BusinessPromotionEnum } from '../../../types/enums/promotion-enum';
import { ShippingTypeEnum } from '../../../types/enums/shipping-type.enum';
import { TaxPayerTypeDescriptionsEnum } from '../../../types/enums/taxpayer-type.enum';
import {
  conditionsAndTariffPersonalaizedMastercardData as conditionsAndTariffPersonalaizedMastercardDataDefault,
  conditionsAndTariffPersonalaizedVisaData as conditionsAndTariffPersonalaizedVisaDataDefault,
  couriers,
  deliveryTimes,
} from '../../../utils/data';
import { addPercentage } from '../../../utils/helpers';
import { pagesPaths } from '../../../utils/navigationUtils';
import schemas from '../../../utils/schemas';
import CommercePersonalizedTariff from './CommercePersonalizedTariff/CommercePersonalizedTariff';

let sameAsMainAddress: boolean = false;

function CommerceTerminalsShipping() {
  const location = useLocation();
  const navigate = useNavigate();
  const localState = location.state;
  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [chosenState, setChosenState] = useState<State | null>(localState?.neighborhood?.city?.state || null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [promotion, setPromotion] = useState<string>('');
  const [showTariff, setShowTariff] = useState<boolean>(false);
  const [acceptVisa, setAcceptVisa] = useState<boolean>(false);
  const [acceptMaster, setAcceptMaster] = useState<boolean>(false);
  const [preSettedMastercardTariff, setPreSettedMastercardTariff] = useState<any[]>([]);
  const [preSettedVisaTariff, setPreSettedVisaTariff] = useState<any[]>([]);
  const [conditionsAndTariffPersonalaizedVisaData, setVisaPersonalizedTableData] = useState<
    CustomTableChunk[]
  >(conditionsAndTariffPersonalaizedVisaDataDefault);
  const [conditionsAndTariffPersonalaizedMastercardData, setMasterPersonalizedTableData] = useState<
    CustomTableChunk[]
  >(conditionsAndTariffPersonalaizedMastercardDataDefault);

  const formRef = useRef<any>(null);

  const handleGoBack = () => navigate(pagesPaths.CommerceBranches, { state: { ...localState } });
  const handleSubmit = () => formRef.current.submitForm();

  const saveTerminalsInformation = async (values: TerminalShippingValues) => {
    setIsLoading(true);

    const hasShippingAddress = !values.withdrawInNAD || values.shippingType !== ShippingTypeEnum.NAD;
    const requestBody: TerminalShippingDataRequest = {
      promotion,
      shippingType: values.shippingType,
      shippingAddress: hasShippingAddress
        ? {
            courier: values.courier,
            addressStreet: values.addressStreet,
            addressNumber: values.addressNumber,
            addressFlatNumber: values.addressFlatNumber,
            addressSolar: values.addressSolar,
            addressPlot: values.addressPlot,
            schedule: values.deliveryTime,
            contactPhone: values.phoneNumber,
            city: values.addressCity,
          }
        : undefined,
      tariff: showTariff
        ? {
            id: localState.tariff?.id ? Number(localState.tariff?.id) : undefined,
            master: acceptMaster
              ? {
                  debit: addPercentage(values.master[0].rows[0].currency),
                  prepaid: addPercentage(values.master[0].rows[1].currency),
                  cashCredit: addPercentage(values.master[0].rows[2].currency),
                  installmentCredit: addPercentage(values.master[0].rows[3].currency),
                  foreignDebit: addPercentage(values.master[0].rows[4].currency),
                  foreignCredit: addPercentage(values.master[0].rows[5].currency),
                }
              : null,
            visa: acceptVisa
              ? {
                  debit: addPercentage(values.visa[0].rows[0].currency),
                  prepaid: addPercentage(values.visa[0].rows[1].currency),
                  cashCredit: addPercentage(values.visa[0].rows[2].currency),
                  installmentCredit: addPercentage(values.visa[0].rows[3].currency),
                  foreignDebit: addPercentage(values.visa[0].rows[4].currency),
                  foreignCredit: addPercentage(values.visa[0].rows[5].currency),
                }
              : null,
          }
        : null,
    };

    const response = await saveTerminalShippingInformation(
      requestBody,
      localState.businessId || localState.id,
    );
    setIsLoading(false);

    if (response.result && response.data) {
      return navigate(pagesPaths.CommerceBeneficiaries, {
        state: { ...localState, ...response.data.businessData },
      });
    }
  };

  const fetchStates = async () => {
    const responseStates = await getStates();
    setStates(responseStates);
  };

  const fetchCities = async (state: State) => {
    const responseCities = await getCities(state.id);
    setCities(responseCities.data);
  };

  const handleOnChangeState = (
    targetValue: string,
    setFieldValue: (name: string, value: string | number) => void,
  ) => {
    setFieldValue('department', +targetValue);
    setFieldValue('addressCity', '');
    setFieldValue('addressNeighborhood', '');
    const chosenState = states.find((state: State) => state.id === +targetValue);

    setChosenState(chosenState || null);
  };

  const handleOnChangeCity = (
    targetValue: string,
    setFieldValue: (name: string, value: string | number) => void,
  ) => {
    setFieldValue('addressCity', +targetValue);
    setFieldValue('addressNeighborhood', '');
  };

  const handleChangeShippingType = (value: string, setFieldValue: any) =>
    setFieldValue('shippingType', value);

  useEffect(() => {
    fetchStates();
    fetchCities(localState?.shippingAddress?.city?.state as State);
  }, [localState?.shippingAddress?.city?.state]);

  useEffect(() => {
    chosenState !== null && fetchCities(chosenState);
  }, [chosenState]);

  const handleFillWithMainAddressData = async (
    setFieldValue: (name: string, value: string | number) => void,
  ) => {
    sameAsMainAddress = !sameAsMainAddress;

    handleOnChangeState(sameAsMainAddress ? String(localState?.city?.stateId!) : '', setFieldValue);
    handleOnChangeCity(sameAsMainAddress ? String(localState.city?.id!) : '', setFieldValue);
    setFieldValue('addressStreet', sameAsMainAddress ? localState.addressStreet : '');
    setFieldValue('addressNumber', sameAsMainAddress ? localState.addressNumber! : '');
    setFieldValue('addressFlatNumber', sameAsMainAddress ? localState.addressFlatNumber : '');
    setFieldValue('addressSolar', sameAsMainAddress ? localState.addressSolar : '');
    setFieldValue('addressPlot', sameAsMainAddress ? localState.addressPlot : '');
  };

  const setBusinessPromotion = (localState: any) => {
    const allowedTaxpayersForDmrPromotion = [
      TaxPayerTypeDescriptionsEnum.MONOTRIBUTO,
      TaxPayerTypeDescriptionsEnum.MONOTRIBUTO_MIDES,
      TaxPayerTypeDescriptionsEnum.PEQUEÑA_EMPRESA,
    ];

    if (localState.nadOnboarding) {
      if (allowedTaxpayersForDmrPromotion.includes(localState?.taxpayerType?.description)) {
        setPromotion(BusinessPromotionEnum.PROMO_12_MESES_DMR);
      } else {
        setPromotion(BusinessPromotionEnum.PROMO_6_MESES);
      }
    } else {
      setPromotion(BusinessPromotionEnum.PROMO_2_MESES);
    }
  };

  const fetchPersonalizedTariffValues = async (businessSector: string) => {
    const serviceResponse = await businessSectorService.getBusinessSectorTable(businessSector);

    setVisaPersonalizedTableData(serviceResponse.visa);
    setMasterPersonalizedTableData(serviceResponse.master);
  };

  useEffect(() => {
    fetchPersonalizedTariffValues(localState.businessCategory.name);
  }, []);

  useEffect(() => {
    setBusinessPromotion(localState);
    localState?.branches?.map((branch: any) => {
      if (!branch.isTariffStandard) {
        setShowTariff(true);
      }

      if (branch.acceptVisa) {
        setAcceptVisa(true);
      }

      if (branch.acceptMaster) {
        setAcceptMaster(true);
      }
    });
  });

  useEffect(() => {
    if (localState.tariff) {
      const masterTariff = conditionsAndTariffPersonalaizedMastercardData[0].rows.map((masterPlan) => {
        return personalizedTariffHelper.processTariffData(masterPlan, localState.tariff, 'master');
      });

      const visaTariff = conditionsAndTariffPersonalaizedVisaData[0].rows.map((visaPlan) => {
        return personalizedTariffHelper.processTariffData(visaPlan, localState.tariff, 'visa');
      });

      setPreSettedMastercardTariff([{ rows: masterTariff }]);
      setPreSettedVisaTariff([{ rows: visaTariff }]);
    }
  }, [conditionsAndTariffPersonalaizedMastercardData, conditionsAndTariffPersonalaizedMastercardData]);

  return (
    <>
      <Container className="commerce-terminals">
        <ProgressBar now={(100 / 8) * 5} />
        <Row>
          <Col lg={12}>
            <h1 className="title">
              <img src={PromotionIcon} alt="Promociones" className="me-3" />
              Promociones
            </h1>
            <div className="promotion-container">
              <p>{promotion}</p>
            </div>
            <Formik
              initialValues={{
                shippingType: localState.shippingType || ShippingTypeEnum.NAD,
                withdrawInNAD: localState.commercialCenter ? !localState.shipping : false,
                directionSameAsResidence: false,
                courier: localState?.shippingAddress?.courier || '',
                deliveryTime: localState?.shippingAddress?.schedule || '',
                phoneNumber: localState?.shippingAddress?.contactPhone || '',
                addressStreet: localState?.shippingAddress?.addressStreet || '',
                addressNumber: localState?.shippingAddress?.addressNumber || '',
                department: localState?.shippingAddress?.city?.state?.id || '',
                addressCity: localState?.shippingAddress?.city?.id || '',
                addressFlatNumber: localState?.shippingAddress?.addressFlatNumber || '',
                addressSolar: localState?.shippingAddress?.addressSolar || '',
                addressPlot: localState?.shippingAddress?.addressPlot || '',
                visa: conditionsAndTariffPersonalaizedVisaData,
                master: conditionsAndTariffPersonalaizedMastercardData,
              }}
              onSubmit={(values) => saveTerminalsInformation(values)}
              innerRef={(formElement: any) => (formRef.current = formElement)}
              validationSchema={schemas.CommerceTerminalsSchema}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <h1 className="title">
                    <img src={POSIcon} alt="POS" className="me-3" />
                    Entrega de POS
                  </h1>
                  <h3 className="section-title mt-4">FORMA DE ENTREGA</h3>
                  <p>La forma de entrega seleccionada aplicará para todos los POS solicitados</p>
                  <div className="col-md-4">
                    <RoundedCheckbox
                      name="shippingType"
                      label="NAD"
                      onChange={handleChangeShippingType}
                      setFieldValue={setFieldValue}
                      value={ShippingTypeEnum.NAD}
                      customClass="px-0"
                      currentComboCheckboxValue={values.shippingType}
                    />
                    <RoundedCheckbox
                      name="shippingType"
                      label="Canal de ventas"
                      onChange={handleChangeShippingType}
                      setFieldValue={setFieldValue}
                      value={ShippingTypeEnum.SALES_CHANNEL}
                      currentComboCheckboxValue={values.shippingType}
                    />
                  </div>
                  {ShippingTypeEnum.NAD === values.shippingType && (
                    <>
                      <Switch
                        htmlFor="withdrawInNAD"
                        id="withdrawInNAD_switch"
                        label="Retirar producto en New Age Data"
                        handleOnClick={() => setFieldValue('withdrawInNAD', !values.withdrawInNAD)}
                        name="withdrawInNAD"
                        containerClass="col-md-12 px-0"
                        checked={values.withdrawInNAD}
                      />
                      {values.withdrawInNAD ? (
                        <InfoBlock
                          title="OFICINAS DE NEW AGE DATA"
                          fields={[
                            { name: 'Dirección', value: 'Buenos Aires 579' },
                            { name: 'Horarios', value: '9:00 a 18:00hs' },
                          ]}
                        />
                      ) : (
                        <Row>
                          <Select name="courier" label="Selector de correo" required>
                            {couriers.map(({ value, label }) => (
                              <option key={value} value={value}>
                                {label}
                              </option>
                            ))}
                          </Select>
                          <div className="input col-md-12 checkbox-label form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              name="sameAsBusinessInfo"
                              id="flexSwitchCheckDefault"
                              onChange={() => {
                                handleFillWithMainAddressData(setFieldValue);
                              }}
                            />
                            <label htmlFor="flexSwitchCheckDefault">
                              Dirección igual al Domicilio Principal
                            </label>
                          </div>
                          <Row>
                            <Select
                              className="col-md-6"
                              label="Departamento"
                              required
                              name="department"
                              handleOnChange={handleOnChangeState}
                              disabled={sameAsMainAddress}
                            >
                              {states.map((state: State) => (
                                <option key={state.id} value={state.id}>
                                  {state.name}
                                </option>
                              ))}
                            </Select>
                            <Select
                              className="col-md-6"
                              name="addressCity"
                              handleOnChange={handleOnChangeCity}
                              label="Localidad"
                              required
                              disabled={sameAsMainAddress}
                            >
                              {cities.map((city: City) => (
                                <option key={city.id} value={city.id}>
                                  {city.name}
                                </option>
                              ))}
                            </Select>
                            <Input
                              className="input col-12"
                              type="text"
                              name="addressStreet"
                              label="Calle"
                              disabled={sameAsMainAddress}
                              required={!values.directionSameAsResidence || !values.withdrawInNAD}
                            />
                            <Input
                              className="input col-md-3"
                              type="text"
                              name="addressNumber"
                              label="N° de puerta"
                              disabled={sameAsMainAddress}
                              required={!values.directionSameAsResidence || !values.withdrawInNAD}
                            />
                            <Input
                              className="input col-md-3"
                              type="number"
                              name="addressFlatNumber"
                              label="Apto (Opcional)"
                              disabled={sameAsMainAddress}
                            />
                            <Input
                              className="input col-md-3"
                              type="number"
                              name="addressSolar"
                              label="Solar (Opcional)"
                              disabled={sameAsMainAddress}
                            />
                            <Input
                              className="input col-md-3"
                              type="number"
                              name="addressPlot"
                              disabled={sameAsMainAddress}
                              label="Manzana (Opcional)"
                            />
                            <Select className="col-md-6 mt-6" label="Horario" name="deliveryTime" required>
                              {deliveryTimes.map(({ value, label }) => (
                                <option key={value} value={value}>
                                  {label}
                                </option>
                              ))}
                            </Select>
                            <Input
                              className="input col-md-6"
                              type="tel"
                              name="phoneNumber"
                              label="Télefono de contacto"
                              required={values.shippingType === ShippingTypeEnum.NAD}
                            />
                          </Row>
                        </Row>
                      )}
                    </>
                  )}
                  {showTariff && (
                    <CommercePersonalizedTariff
                      masterData={
                        localState.tariff
                          ? preSettedMastercardTariff
                          : conditionsAndTariffPersonalaizedMastercardData
                      }
                      visaData={
                        localState.tariff ? preSettedVisaTariff : conditionsAndTariffPersonalaizedVisaData
                      }
                      acceptMaster={acceptMaster}
                      acceptVisa={acceptVisa}
                    />
                  )}
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
      <NavigationFooter previousHandler={handleGoBack} nextHandler={handleSubmit} loading={isLoading} />
    </>
  );
}
export default CommerceTerminalsShipping;

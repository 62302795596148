import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import ArrowDown from '../../../../assets/arrow-down.svg';
import ArrowUp from '../../../../assets/arrow-up.svg';
import RHFRoundedCheckbox from '../../../../components/ReactHookFormComponents/Input/RHFRoundedCheckbox';
import RHFSelect from '../../../../components/ReactHookFormComponents/Input/RHFSelect';
import { getBranchTableTariffData } from '../../../../services/commerce.service';
import { ConditionsAndTariffLocationState, CustomTableChunk } from '../../../../types/add_commerce.types';
import { BusinessSectorEnum } from '../../../../types/enums/business-sector.enum';
import { ConditionsTariffEnum } from '../../../../types/enums/conditions-tariff.enum';
import {
  conditionsAndTariffPersonalaizedMastercardData as conditionsAndTariffPersonalaizedMastercardDataDefault,
  conditionsAndTariffPersonalaizedVisaData as conditionsAndTariffPersonalaizedVisaDataDefault,
} from '../../../../utils/data';
import { formatTableDataForBranchTariff, getEnumTypeValue } from '../../../../utils/helpers';
import BranchStandardTariffTable from './BranchStandardTariffTable/BranchStandardTariffTable';

function BranchTariff() {
  const { control, setValue, getValues } = useFormContext();
  const [showTariff, setShowTariff] = useState<boolean>(true);
  const [preSettedMastercardTariff, setPreSettedMastercardTariff] = useState<any[]>([]);
  const [preSettedVisaTariff, setPreSettedVisaTariff] = useState<any[]>([]);
  const [tableDataVisa, setTableDataVisa] = useState<any[]>([]);
  const [tableDataMaster, setTableDataMaster] = useState<any[]>([]);
  const location = useLocation();
  const [conditionsAndTariffPersonalaizedVisaData, setVisaPersonalizedTableData] = useState<
    CustomTableChunk[]
  >(conditionsAndTariffPersonalaizedVisaDataDefault);
  const [conditionsAndTariffPersonalaizedMastercardData, setMasterPersonalizedTableData] = useState<
    CustomTableChunk[]
  >(conditionsAndTariffPersonalaizedMastercardDataDefault);
  const managerHasTariffRestriction = localStorage.getItem('tariffRestriction') === 'true';
  const localState: ConditionsAndTariffLocationState = location.state as ConditionsAndTariffLocationState;
  const isBusinessSectorFuels =
    BusinessSectorEnum[getEnumTypeValue('businessCategory', localState.businessCategory!)] ===
    BusinessSectorEnum[BusinessSectorEnum.COMBUSTIBLES];

  const acceptVisa = useWatch({ control, name: 'tariff.acceptVisa' });
  const acceptMaster = useWatch({ control, name: 'tariff.acceptMaster' });
  const branchCategory = useWatch({ control, name: 'businessSubCategory' });
  const tariffTypeValue = useWatch({ control, name: 'tariff.tariffType' });
  const branchBusinessCategory = useWatch({ control, name: 'businessSubCategory' });
  const tariffData = getValues('tariff.tariff');

  const toggleShowTariff = () => {
    setShowTariff((prevState) => !prevState);
  };

  useEffect(() => {
    if (tariffData) {
      const masterTariff = conditionsAndTariffPersonalaizedMastercardData[0].rows.map((masterPlan) => {
        switch (masterPlan.plan) {
          case 'DÉBITO':
            masterPlan.currency = Number(localState.tariff?.masterDebit?.replace('%', ''));
            return masterPlan;
          case 'PREPAGO':
            masterPlan.currency = Number(localState.tariff?.masterPrepaid?.replace('%', ''));
            return masterPlan;
          case 'CRÉDITO CONTADO':
            masterPlan.currency = Number(localState.tariff?.masterCashCredit?.replace('%', ''));
            return masterPlan;
          case 'CRÉDITO CUOTAS':
            masterPlan.currency = Number(localState.tariff?.masterInstallmentCredit?.replace('%', ''));
            return masterPlan;
          case 'Extranjeros DÉBITO':
            masterPlan.currency = Number(localState.tariff?.masterForeignDebit?.replace('%', ''));
            return masterPlan;
          case 'Extranjeros CRÉDITO':
            masterPlan.currency = Number(localState.tariff?.masterForeignCredit?.replace('%', ''));
            return masterPlan;
          default:
            break;
        }
      });

      const visaTariff = conditionsAndTariffPersonalaizedVisaData[0].rows.map((masterPlan) => {
        switch (masterPlan.plan) {
          case 'DÉBITO':
            masterPlan.currency = Number(localState.tariff?.visaDebit?.replace('%', ''));
            return masterPlan;
          case 'PREPAGO':
            masterPlan.currency = Number(localState.tariff?.visaPrepaid?.replace('%', ''));
            return masterPlan;
          case 'CRÉDITO CONTADO':
            masterPlan.currency = Number(localState.tariff?.visaCashCredit?.replace('%', ''));
            return masterPlan;
          case 'CRÉDITO CUOTAS':
            masterPlan.currency = Number(localState.tariff?.visaInstallmentCredit?.replace('%', ''));
            return masterPlan;
          case 'Extranjeros DÉBITO':
            masterPlan.currency = Number(localState.tariff?.visaForeignDebit?.replace('%', ''));
            return masterPlan;
          case 'Extranjeros CRÉDITO':
            masterPlan.currency = Number(localState.tariff?.visaForeignCredit?.replace('%', ''));
            return masterPlan;
          default:
            break;
        }
      });

      setPreSettedMastercardTariff([{ rows: masterTariff }]);
      setPreSettedVisaTariff([{ rows: visaTariff }]);
    }
  }, [conditionsAndTariffPersonalaizedMastercardData, conditionsAndTariffPersonalaizedMastercardData]);

  const handleChangeTariff = (value: string) => {
    setValue('tariff.tariffType', value);
  };

  useEffect(() => {
    const getTableData = async () => {
      const response = await getBranchTableTariffData(branchBusinessCategory);

      if (response.result) {
        const formattedTableDataVisa = formatTableDataForBranchTariff(response.data.visa[0].rows);
        setTableDataVisa(formattedTableDataVisa);

        const formattedTableDataMaster = formatTableDataForBranchTariff(response.data.master[0].rows);
        setTableDataMaster(formattedTableDataMaster);
      }
    };

    if (branchBusinessCategory) {
      getTableData();
    }
  }, [branchBusinessCategory]);

  return (
    <div className="branch-tariff-container">
      <div className="branch-tariff-title-container">
        <h3 className="branch-form-title">TARIFARIO</h3>
        <Button
          className="branch-show-tariff-button"
          aria-label="Mostrar tarifario"
          onClick={toggleShowTariff}
        >
          <img src={showTariff ? ArrowUp : ArrowDown} alt="" />
        </Button>
      </div>
      <hr />
      {showTariff && (
        <div className="branch-tariff-form-container">
          <p>Selecciona según corresponda</p>
          <div className="branch-tariff-checkboxs-container">
            <RHFRoundedCheckbox
              name="tariff.tariffType"
              label="Estándar"
              onChange={handleChangeTariff}
              value={ConditionsTariffEnum.STANDARD}
              customClass="px-0"
              currentComboCheckboxValue={tariffTypeValue}
            />
            <RHFRoundedCheckbox
              name="tariff.tariffType"
              label="Personalizado"
              onChange={handleChangeTariff}
              value={ConditionsTariffEnum.PERSONALIZED}
              currentComboCheckboxValue={tariffTypeValue}
            />
          </div>
          <div className="branch-tariff-issuers-container">
            <RHFSelect
              className="col-md-12 processor-separator issuer-select"
              name="tariff.acceptVisa"
              label="¿Operará con VISA?"
              required
            >
              <option value="yes">Sí</option>
              <option value="no">No</option>
            </RHFSelect>
            <RHFSelect
              className="col-md-12 issuer-select"
              name="tariff.acceptMaster"
              label="¿Operará con MASTER?"
              required
            >
              <option value="yes">Sí</option>
              <option value="no">No</option>
            </RHFSelect>
          </div>
          {tariffTypeValue === ConditionsTariffEnum.STANDARD ? (
            <>
              {branchCategory ? (
                <>
                  {acceptVisa === 'yes' && (
                    <BranchStandardTariffTable
                      header={{ title: 'Propuesta VISA', value: 'Arancel' }}
                      items={tableDataVisa}
                    />
                  )}
                  {acceptMaster === 'yes' && (
                    <div className="branch-tariff-master-container">
                      <BranchStandardTariffTable
                        header={{ title: 'Propuesta MASTER', value: 'Arancel' }}
                        items={tableDataMaster}
                      />
                    </div>
                  )}
                </>
              ) : (
                <p className="personalized-tariff-warning">
                  <strong>Es necesario seleccionar un sub rubro para visualizar el tarifario</strong>
                </p>
              )}
            </>
          ) : (
            <p className="personalized-tariff-warning">
              <strong>El tarifario personalizado se completa en el siguiente paso</strong>
            </p>
          )}
        </div>
      )}
    </div>
  );
}

export default BranchTariff;

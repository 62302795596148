interface BranchTariffTableProps {
  header: { title: string; value: string };
  items: { title: string; value: string }[];
}

function BranchStandardTariffTable({ header, items }: BranchTariffTableProps) {
  return (
    <div className="branch-tariff-table-container">
      <p className="branch-tariff-table-header">
        <span className="branch-tariff-table-value first header-text">{header.title}</span>
        <span className="branch-tariff-table-value header-text">{header.value}</span>
      </p>
      {items.map((item) => (
        <p className="branch-tariff-table-item">
          <span className="branch-tariff-table-value first">{item.title}</span>
          <span className="branch-tariff-table-value">{item.value}</span>
        </p>
      ))}
    </div>
  );
}

export default BranchStandardTariffTable;

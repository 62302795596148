import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Row } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import TrashIcon from '../../../../../../assets/trash-icon.svg';
import RHFInput from '../../../../../../components/ReactHookFormComponents/Input/RHFInput';
import RHFSelect from '../../../../../../components/ReactHookFormComponents/Input/RHFSelect';
import CreateBranchSchemaType from '../../../../../../types/branchOffice/branch-office-form';
import { commerceCodesCardBrands } from '../../../../../../utils/data';

function BranchCommerceCodes() {
  const { control } = useFormContext<CreateBranchSchemaType>();
  const {
    fields: commerceCodes,
    append: appendCommerceCode,
    remove: removeCommerceCode,
  } = useFieldArray({
    control,
    name: 'nadProducts.terminalPlan.commerceCodes',
  });

  const addCommerceCodeHandler = () => {
    appendCommerceCode({ cardBrand: '', acquirer: '', code: '' });
  };

  return (
    <div>
      {commerceCodes.map((commerceCode, index: number) => (
        <Row>
          <RHFSelect
            className="col-md-4"
            name={`nadProducts.terminalPlan.commerceCodes.${index}.cardBrand`}
            label="Tarjeta"
            required
          >
            {commerceCodesCardBrands.map((cardBrand) => (
              <option key={cardBrand.value} value={cardBrand.value}>
                {cardBrand.label}
              </option>
            ))}
          </RHFSelect>
          <RHFInput
            className="col-md-4"
            type="text"
            name={`nadProducts.terminalPlan.commerceCodes.${index}.acquirer`}
            label="Adquirente"
            required
          />
          <RHFInput
            className="col-md-3"
            type="text"
            name={`nadProducts.terminalPlan.commerceCodes.${index}.code`}
            label="Comercio"
            required
          />
          <Button
            className="branch-bank-account-delete-button"
            aria-label="Eliminar código de comercio"
            onClick={() => removeCommerceCode(index)}
          >
            <img src={TrashIcon} alt="" />
          </Button>
        </Row>
      ))}
      <div className="add-commerce-code-button-container">
        <Button onClick={addCommerceCodeHandler} className="share-button add-button">
          <FontAwesomeIcon icon={faPlus as IconProp} />
          Agregar código de comercio
        </Button>
      </div>
    </div>
  );
}

export default BranchCommerceCodes;

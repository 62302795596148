import { BusinessTableRow } from '../types/add_commerce.types';
import { numberHelper } from './number.helper';

const processTariffData = (selectedPlan: BusinessTableRow, tariff: any, brand: string) => {
  switch (selectedPlan.plan) {
    case 'DÉBITO':
      selectedPlan.currency = numberHelper.percentageToNumber(tariff[`${brand}Debit`]);
      return selectedPlan;
    case 'PREPAGO':
      selectedPlan.currency = numberHelper.percentageToNumber(tariff[`${brand}Prepaid`]);
      return selectedPlan;
    case 'CRÉDITO CONTADO':
      selectedPlan.currency = numberHelper.percentageToNumber(tariff[`${brand}CashCredit`]);
      return selectedPlan;
    case 'CRÉDITO CUOTAS':
      selectedPlan.currency = numberHelper.percentageToNumber(tariff[`${brand}InstallmentCredit`]);
      return selectedPlan;
    case 'Extranjeros DÉBITO':
      selectedPlan.currency = numberHelper.percentageToNumber(tariff[`${brand}ForeignDebit`]);
      return selectedPlan;
    case 'Extranjeros CRÉDITO':
      selectedPlan.currency = numberHelper.percentageToNumber(tariff[`${brand}ForeignCredit`]);
      return selectedPlan;
    default:
      break;
  }
};

export const personalizedTariffHelper = { processTariffData };

export enum CardBrands {
  AMEX = 'AMEX',
  ANDA = 'ANDA',
  CABAL = 'CABAL',
  CABAL_DOLARES = 'CABAL_DOLARES',
  CLUB_DEL_ESTE = 'CLUB_DEL_ESTE',
  CREDITEL = 'CREDITEL',
  LIDER_DINERS = 'LIDER_DINERS',
  OCA_PRODUCTOS = 'OCA_PRODUCTOS',
  PASSCARD = 'PASSCARD',
  TARJETA_D = 'TARJETA_D',
}

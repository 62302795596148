import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import longTailLogo from '../../../assets/Long-tail.png';
import NavigationFooter from '../../../components/NavigationFooter/NavigationFooter';
import { deleteBeneficiary, updateBusinessBeneficiaries } from '../../../services/commerce.service';
import {
  AddCommerceSecondStepLocationState,
  NewBeneficiaryFamiliarPepValues,
  NewBeneficiaryValues,
} from '../../../types/add_commerce.types';
import { Beneficiary } from '../../../types/business/request';
import { DocumentTypeEnum } from '../../../types/enums/document-type.enum';
import { newBeneficiary } from '../../../utils/data';
import { doRefsSubmissions } from '../../../utils/formikHelpers';
import { getBeneficiaries, triggerToasts } from '../../../utils/helpers';
import { pagesPaths } from '../../../utils/navigationUtils';
import NewBeneficiary from './NewBeneficiary';

function CommerceBeneficiaries() {
  const location = useLocation();
  const navigate = useNavigate();
  const formRefs = useRef<any>([]);
  const formFamilyRefs = useRef<any>([]);
  const localState: AddCommerceSecondStepLocationState = location.state as AddCommerceSecondStepLocationState;
  const [beneficiaries, setBeneficiaries] = useState<Array<NewBeneficiaryValues>>(
    getBeneficiaries(localState?.branches),
  );
  const [submittedBeneficiaries, setSubmittedBeneficiaries] = useState<Array<NewBeneficiaryValues>>([]);
  const [submittedFamiliarPeps, setSubmittedFamiliarPeps] = useState<NewBeneficiaryFamiliarPepValues[]>([]);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const submitNewBeneficiary = (values: NewBeneficiaryValues, index: number) => {
    const updateArray = [...submittedBeneficiaries];
    updateArray[index] = values;
    setSubmittedBeneficiaries(updateArray);
  };

  const submitBeneficiaryFamiliarPep = (values: NewBeneficiaryFamiliarPepValues, index: number) => {
    const updateArray = [...submittedFamiliarPeps];
    updateArray[index] = values;
    setSubmittedFamiliarPeps(updateArray);
  };

  const handleGoBack = () => navigate(pagesPaths.CommerceTerminalsShipping, { state: { ...localState } });

  const postBeneficiary = async (updatedSubmittedBeneficiaries: NewBeneficiaryValues[]) => {
    setLoading(true);

    const beneficiaries: Beneficiary[] = updatedSubmittedBeneficiaries.map(
      (beneficiary: NewBeneficiaryValues) => {
        let formattedBeneficiary = {
          branchId: beneficiary.branchOfficeId!,
          documentType:
            typeof beneficiary.documentType === 'string'
              ? DocumentTypeEnum[beneficiary.documentType as keyof typeof DocumentTypeEnum]
              : (beneficiary.documentType as DocumentTypeEnum),
          documentNumber: beneficiary.documentNumber,
          firstName: beneficiary.firstName,
          lastName: beneficiary.lastName,
          participationPercentage: beneficiary.participationPercentage!,
          isPep: beneficiary.isPep,
          isRelative: beneficiary.isRelative,
          id: beneficiary.beneficiary_branch_office
            ? beneficiary.beneficiary_branch_office.beneficiaryId
            : null,
        };
        if (formattedBeneficiary.isRelative) {
          const formattedBeneficiaryWithRelative = {
            ...formattedBeneficiary,
            relativeDocumentType:
              typeof beneficiary.relativeDocumentType === 'string'
                ? DocumentTypeEnum[beneficiary.relativeDocumentType as keyof typeof DocumentTypeEnum]
                : (beneficiary.relativeDocumentType as DocumentTypeEnum),
            relativeDocumentNumber: beneficiary.relativeDocumentNumber,
            relativeFirstName: beneficiary.relativeFirstName,
            relativeLastName: beneficiary.relativeLastName,
          };
          return formattedBeneficiaryWithRelative;
        }
        return formattedBeneficiary;
      },
    );

    const response = await updateBusinessBeneficiaries(localState.id!, { beneficiaries });

    if (response.result && response.data) {
      const businessData = response.data.businessData || response.data;

      setLoading(false);

      return navigate(pagesPaths.CommerceDocumentation, { state: { ...localState, ...businessData } });
    }
    triggerToasts(response);
    setLoading(false);
  };

  useEffect(() => {
    if (
      submitted &&
      submittedBeneficiaries.length === beneficiaries.length &&
      submittedBeneficiaries.filter((beneficiary) => beneficiary?.isRelative).length ===
        submittedFamiliarPeps.filter((beneficiary) => !!beneficiary).length
    ) {
      const updatedSubmittedBeneficiaries = submittedBeneficiaries.map(
        (beneficiary: NewBeneficiaryValues, index: number) =>
          beneficiary.isRelative ? { ...beneficiary, ...submittedFamiliarPeps[index] } : beneficiary,
      );
      setSubmitted(false);
      postBeneficiary(updatedSubmittedBeneficiaries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted, submittedBeneficiaries, submittedFamiliarPeps, localState, navigate, beneficiaries]);

  const handleSubmit = () => {
    if (formRefs.current && formFamilyRefs.current) {
      const didFormRefsSubmitted: boolean = doRefsSubmissions(formRefs);
      const didFormFamilyRefsSubmitted: boolean = doRefsSubmissions(formFamilyRefs);

      didFormRefsSubmitted && didFormFamilyRefsSubmitted && setSubmitted(true);
    }
  };

  const handleAddNewBeneficiary = () =>
    setBeneficiaries([...beneficiaries, { ...newBeneficiary, localId: uuidv4() }]);

  const handleDelete = async (index: number) => {
    const beneficiaryToDelete = beneficiaries.find(
      (beneficiary: NewBeneficiaryValues, filterIndex: number) => index === filterIndex,
    );

    if (
      beneficiaryToDelete?.id &&
      (beneficiaryToDelete.branchOfficeId || beneficiaryToDelete.branchOfficeId === 0)
    ) {
      const response = await deleteBeneficiary(
        localState.id!,
        beneficiaryToDelete.branchOfficeId!,
        beneficiaryToDelete.id,
      );

      if (response.result) {
        return setBeneficiaries(getBeneficiaries(response.data.branches));
      }
      return triggerToasts(response);
    }

    setBeneficiaries((prevState) =>
      prevState.filter((beneficiary: NewBeneficiaryValues, filterIndex: number) => index !== filterIndex),
    );
  };

  return (
    <>
      <Container className="beneficiaries">
        <ProgressBar now={(100 / 8) * 6} />
        <Row>
          <Col lg={12}>
            <h1>
              <img src={longTailLogo} alt="Comercio" className="me-3" /> Beneficiario final
            </h1>
            <br />
            {beneficiaries.map((beneficiary: NewBeneficiaryValues, index: number) => {
              return (
                <NewBeneficiary
                  innerRef={(formElement: HTMLFormElement) => (formRefs.current[index] = formElement)}
                  submitNewBeneficiary={submitNewBeneficiary}
                  submitBeneficiaryFamiliarPep={submitBeneficiaryFamiliarPep}
                  innerRefFamiliarPep={(formElement: HTMLFormElement) =>
                    (formFamilyRefs.current[index] = formElement)
                  }
                  index={index}
                  key={beneficiary.localId || `${beneficiary.documentNumber}_${index}`}
                  branches={localState?.branches}
                  beneficiaryData={beneficiary}
                  handleDelete={handleDelete}
                />
              );
            })}
          </Col>
        </Row>
        <div>
          <Button onClick={handleAddNewBeneficiary} className="add-section">
            <FontAwesomeIcon icon={faPlus as IconProp} />
            Agregar beneficiario
          </Button>
        </div>
      </Container>
      <NavigationFooter previousHandler={handleGoBack} nextHandler={handleSubmit} loading={loading} />
    </>
  );
}

export default CommerceBeneficiaries;

import { Container, Row } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import RHFSwitch from '../../../../components/ReactHookFormComponents/Input/RHFSwitch';
import CreateBranchSchemaType from '../../../../types/branchOffice/branch-office-form';
import { BranchTerminals } from '../../../../types/branchOffice/response';
import Ecommerce from './Ecommerce/Ecommerce';
import NewAgeDataProducts from './NewAgeData/NewAgeDataProducts';

interface BranchProductsProps {
  presettedTerminals?: BranchTerminals[];
}

function BranchProducts({ presettedTerminals }: BranchProductsProps) {
  const { control } = useFormContext<CreateBranchSchemaType>();
  const hasNadProducts = useWatch({ control, name: 'hasNadProducts' });
  const hasNonPresenceCard = useWatch({ control, name: 'hasNonPresenceCard' });

  return (
    <Container>
      <Row>
        <fieldset>
          <legend className="branch-form-title">PRODUCTOS</legend>
          <hr />
          <p>Debes elegir al menos un producto para incorporar a esta sucursal</p>
          <div className="branch-product-item-container">
            <div className="branch-product-item-action">
              <h3 className="branch-product-item-title">New Age Data</h3>
              <div className="form-check form-switch">
                <RHFSwitch
                  id="hasNadProducts"
                  label=""
                  name="hasNadProducts"
                  containerClass="col-md-12 mt-0 px-0 pb-0"
                />
              </div>
            </div>
            {hasNadProducts && <NewAgeDataProducts presettedTerminals={presettedTerminals} />}
          </div>
          <div className="branch-product-item-container">
            <div className="branch-product-item-action">
              <h3 className="branch-product-item-title">Tarjeta no presencial</h3>
              <div className="form-check form-switch">
                <RHFSwitch
                  id="hasNonPresenceCard"
                  label=""
                  name="hasNonPresenceCard"
                  containerClass="col-md-12 mt-0 px-0 pb-0"
                />
              </div>
            </div>
            {hasNonPresenceCard && <Ecommerce />}
          </div>
        </fieldset>
      </Row>
    </Container>
  );
}

export default BranchProducts;

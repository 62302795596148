import { Field, Form, Formik } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from '../../components/Input/Select';
import { getTariffsByBusinessSector } from '../../services/commerce.service';
import { createEcommerce } from '../../services/ecommerce.service';
import { CustomTableChunk } from '../../types/add_commerce.types';
import { CreateEcommerceRequest } from '../../types/business/request';
import { ECommerceProcessorEnum } from '../../types/enums/ecommerce-processor.enum';
import {
  integrationType,
  masterPersonalizedComisionHeadings,
  visaPersonalizedComisionHeadings,
} from '../../utils/data';
import { capitalize, formatPersonalizedTable, triggerToasts } from '../../utils/helpers';
import { pagesPaths } from '../../utils/navigationUtils';
import RoundedCheckbox from '../Input/RadioButton';
import Switch from '../Input/Switch';
import PersonalaizedTariffTable from '../PersonalizedTariffTable/PersonalizedTariffTable';

interface ECommerceBlockProps {
  title: string;
  formRef: any;
  businessSector: number | undefined;
  businessId: number | undefined;
  setIsLoading: any;
}

interface CheckboxOption {
  name: string;
  value: number;
}

function ECommerceBlock({ title, formRef, businessSector, businessId, setIsLoading }: ECommerceBlockProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const [masterPersonalizedValues, setMasterPersonalizedValues] = useState<Array<CustomTableChunk>>([]);
  const [visaPersonalizedValues, setVisaPersonalizedValues] = useState<Array<CustomTableChunk>>([]);

  const localState: any = location.state;

  const isGetNetManager = localStorage.getItem('canAccessPersonalizedTariff') === 'true';
  const handleBlurPersonalaizedTable = (
    event: ChangeEvent<HTMLInputElement>,
    tableData: Array<CustomTableChunk>,
    setFieldValue: (field: string, value: CustomTableChunk[]) => void,
    field: string,
  ) => {
    const value = event.target.value;
    const id = event.target.id.split('_')[1];
    const propousalValues = tableData[0].rows[parseInt(id)];
    const max = propousalValues.fees;
    const min = propousalValues.deferred;
    const newTableData = [...tableData];

    if (value > max) {
      newTableData[0].rows[parseInt(id)].currency = max;

      triggerToasts('El valor ingresado supera el máximo permitido', false);
      setFieldValue(field, newTableData);
    } else if (value < min) {
      newTableData[0].rows[parseInt(id)].currency = min;

      triggerToasts('El valor ingresado es menor al mínimo permitido', false);
      setFieldValue(field, newTableData);
    } else setFieldValue(field, newTableData);
  };

  useEffect(() => {
    const getTariffs = async () => {
      const response = await getTariffsByBusinessSector(businessSector!);

      if (response?.result && response.data) {
        const { mastercard, visa } = formatPersonalizedTable(response.data);

        setMasterPersonalizedValues(mastercard);
        setVisaPersonalizedValues(visa);

        formRef.current.setFieldValue('master', mastercard);
        formRef.current.setFieldValue('visa', visa);
      } else {
        triggerToasts('No se pudo obtener las tarifas', false);
      }
    };

    getTariffs();
  }, [formRef, businessSector]);

  const handleChangePersonalaizedTable = (
    event: ChangeEvent<HTMLInputElement>,
    tableData: Array<CustomTableChunk>,
    setFieldValue: (field: string, value: CustomTableChunk[]) => void,
    field: string,
  ) => {
    const value = event.target.value;
    const id = event.target.id.split('_')[1];
    const newTableData = [...tableData];

    newTableData[0].rows[parseInt(id)].currency = value;

    setFieldValue(field, newTableData);
  };

  const handleChangePaymentProcessor = (value: string, setFieldValue: any) =>
    setFieldValue('paymentProcessor', value);

  const handleCheckboxIntegrationType = (
    option: CheckboxOption,
    values: any,
    setFieldValue: (name: string, value: Array<number>) => void,
  ) => {
    if (values.integrationType.includes(option.value)) {
      setFieldValue(
        'integrationType',
        values.integrationType.filter((id: number) => id !== option.value),
      );
    } else {
      setFieldValue('integrationType', [...values.integrationType, option.value]);
    }
  };

  const handleSubmitEcommerce = async (values: any) => {
    setIsLoading(true);
    const { paymentProcessor } = values;
    const integrationTypeValues = values.integrationType.filter((value: number) => value !== 0);

    const requestBody: CreateEcommerceRequest = {
      ecommerceIntegrationType: integrationTypeValues,
      ecommerceProcessor: paymentProcessor,
    };

    if (isGetNetManager && values.master!) {
      const tariff = {
        ...(JSON.parse(values.masterOperates) && {
          masterCard: {
            debit: +values?.master?.[0]?.rows?.[0]?.currency,
            cashCredit: +values?.master?.[0]?.rows?.[2]?.currency,
            installmentCredit: +values?.master?.[0]?.rows?.[3]?.currency,
          },
        }),
        ...(JSON.parse(values.visaOperates) && {
          visa: {
            debit: +values?.visa?.[0]?.rows?.[0]?.currency,
            cashCredit: +values?.visa?.[0]?.rows?.[2]?.currency,
            installmentCredit: +values?.visa?.[0]?.rows?.[3]?.currency,
          },
        }),
      };

      requestBody.ecommerceTariff = tariff;
    }

    const response = await createEcommerce(businessId!, requestBody);

    if (response?.result) {
      setIsLoading(false);
      return navigate(pagesPaths.CommerceContracts, {
        state: { ...localState, ...response.data.businessData },
      });
    }
    triggerToasts('Ocurrio un error al guardar la información', false);
    setIsLoading(false);
  };

  return (
    <Formik
      initialValues={{
        ecommerce: false,
        integrationType: [0],
        tariffs: '',
        paymentProcessor: localState?.ecommerce?.processor || '',
        master: localState?.ecommerce?.master || '',
        visa: localState?.ecommerce?.visa || '',
        visaOperates: 'false',
        masterOperates: 'false',
      }}
      onSubmit={(values: any) => handleSubmitEcommerce(values)}
      innerRef={(formElement: any) => (formRef.current = formElement)}
    >
      {({ setFieldValue, values }) => (
        <Form className="h-100">
          <Row className="text-center">
            <div className="confirmation-card border-none w-100 mt-4">
              <p className="confirmation-title">{title.toUpperCase()}</p>
              <Switch
                htmlFor="ecommerce"
                id="ecommerce_switch"
                label="Agregar e-commerce al comercio"
                handleOnClick={() => setFieldValue('ecommerce', !values.ecommerce)}
                name="ecommerce"
                containerClass="col-md-12 mt-0 px-0"
                checked={values.ecommerce}
              />
              {values.ecommerce && (
                <>
                  <div className="col-md-4">
                    <p className="subtitle mb-2">¿Tienes pasarela de pago?</p>
                    <div className="col-md-12 mt-1 d-flex justify-content-start">
                      <RoundedCheckbox
                        name="paymentProcessor"
                        label={capitalize(ECommerceProcessorEnum.PLEXO)}
                        onChange={handleChangePaymentProcessor}
                        setFieldValue={setFieldValue}
                        value={ECommerceProcessorEnum.PLEXO}
                        customClass="px-0"
                        currentComboCheckboxValue={values.paymentProcessor}
                      />
                      <RoundedCheckbox
                        name="paymentProcessor"
                        label={capitalize(ECommerceProcessorEnum.BAMBOO)}
                        onChange={handleChangePaymentProcessor}
                        setFieldValue={setFieldValue}
                        value={ECommerceProcessorEnum.BAMBOO}
                        currentComboCheckboxValue={values.paymentProcessor}
                      />
                      <RoundedCheckbox
                        name="paymentProcessor"
                        label={capitalize(ECommerceProcessorEnum.OTRO)}
                        onChange={handleChangePaymentProcessor}
                        setFieldValue={setFieldValue}
                        value={ECommerceProcessorEnum.OTRO}
                        currentComboCheckboxValue={values.paymentProcessor}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex flex-column mt-4">
                    <p className="subtitle mb-2">Seleccione el tipo de producto</p>
                    <div className="col d-flex justify-content-between">
                      {integrationType.map((option: CheckboxOption, index: number) => (
                        <label
                          key={`${option.name}_${index}`}
                          className="checkbox-label d-flex align-items-center mt-1"
                          htmlFor={option.name}
                        >
                          <Field
                            className="checkbox"
                            type="checkbox"
                            name="integrationType"
                            id={option.name}
                            checked={values?.integrationType?.includes(option.value)}
                            onChange={() => handleCheckboxIntegrationType(option, values, setFieldValue)}
                          />
                          {option.name}
                        </label>
                      ))}
                    </div>
                  </div>
                  {isGetNetManager && (
                    <>
                      <Select
                        className="col-md-12 processor-separator"
                        name="visaOperates"
                        label={'¿Operará con VISA?'}
                        required
                      >
                        <option key={1} value="true">
                          Sí
                        </option>
                        <option key={2} value="false">
                          No
                        </option>
                      </Select>
                      <Select
                        className="col-md-12"
                        name="masterOperates"
                        label={'¿Operará con MASTER?'}
                        required
                      >
                        <option key={1} value="true">
                          Sí
                        </option>
                        <option key={2} value="false">
                          No
                        </option>
                      </Select>
                      {JSON.parse(values.visaOperates) && (
                        <PersonalaizedTariffTable
                          setFieldValue={setFieldValue}
                          field="visa"
                          tableData={visaPersonalizedValues}
                          handleChangePersonalaizedTable={handleChangePersonalaizedTable}
                          handleBlurPersonalaizedTable={handleBlurPersonalaizedTable}
                          headings={visaPersonalizedComisionHeadings}
                        />
                      )}
                      {JSON.parse(values.masterOperates) && (
                        <PersonalaizedTariffTable
                          setFieldValue={setFieldValue}
                          field="mastercard"
                          tableData={masterPersonalizedValues}
                          handleChangePersonalaizedTable={handleChangePersonalaizedTable}
                          handleBlurPersonalaizedTable={handleBlurPersonalaizedTable}
                          headings={masterPersonalizedComisionHeadings}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

export default ECommerceBlock;
